const skills = [
    {
        id:1,
        name: "JS",
        points:"7",
        description: "js desc",
        deployedLink: "https://www.javascript.com/",
        image:"https://img.icons8.com/color/96/000000/javascript--v2.png"
    },
    {
        id:2,
        name: "React.js",
        points:"8",
        description: "",
        deployedLink: "https://reactjs.org/docs/getting-started.html",
        image:"https://img.icons8.com/plasticine/96/000000/react.png"
    }, 
    {
        id:3,
        points:"5",
        name: "Redux",
        description: "",
        deployedLink: "https://redux.js.org/",
        image:"https://img.icons8.com/color/96/000000/redux.png"
    },
    {
        id:30,
        points:"3",
        name: "Gatsby",
        description: "",
        deployedLink: "https://www.gatsbyjs.com/",
        image:"https://img.icons8.com/fluency/96/null/gatsbyjs.png"
    }, 
    {
        id:4,
        points:"7",
        name: "Node.js",
        description: " ",
        deployedLink: "https://nodejs.org/en/",
        image:"https://img.icons8.com/color/96/000000/nodejs.png"
    }, 
    {
        id:5,
        points:"7",
        name: "HTML5",
        description: " ",
        deployedLink: "https://www.w3schools.com/html/",
        image:"https://img.icons8.com/material-outlined/96/000000/html-5.png"
    }, 
    {
        id:6,
        name: "CSS3",
        points:"4",
        description: " ",
        deployedLink: "https://www.w3schools.com/css/",
        image:"https://img.icons8.com/color/96/000000/css3.png"
    }, 
    {
        id:7,
        points:"5",
        name: "MUI",
        description: " ",
        deployedLink: "https://v4.mui.com/getting-started/installation/",
        image:"https://img.icons8.com/color/96/000000/material-ui.png"
    }, 
    {
        id:8,
        points:"3",
        name: "Python",
        description: "BigData With Hadoop Framework(Lecture-4)-MapReduce",
        deployedLink: "https://www.python.org/",
        image:"https://img.icons8.com/color/96/000000/python.png"
    }, 
    {
        id:9,
        points:"3",
        name: "AWS",
        description: "",
        deployedLink: "https://docs.aws.amazon.com/index.html?nc2=h_ql_doc_do",
        image:"https://img.icons8.com/color/96/000000/amazon-web-services.png"
    }, 
    {
        id:10,
        points:"3",
        name: "Git",
        description: " ",
        deployedLink: "https://git-scm.com/docs",
        image:"https://img.icons8.com/color/96/000000/git.png"
    }, 
    {
        id:11,
        points:"3",
        name: "Jira",
        description: "",
        deployedLink: "https://confluence.atlassian.com/jira/jira-documentation-1556.html",
        image:"https://img.icons8.com/color/96/000000/jira.png"
    }, 

    {
        id:12,
        points:"3",
        name: "Sql",
        description: "",
        deployedLink: "https://www.w3schools.com/sql/default.Asp",
        image:"https://img.icons8.com/color/96/000000/sql.png"
    }, 
    {
        id:13,
        points:"1",
        name: "MongoDB",
        description: "",
        deployedLink: "https://docs.mongodb.com/",
        image:"https://img.icons8.com/color/96/000000/mongodb.png"
    }, 
    {
        id:14,
        points:"3",
        name: "Swagger",
        description: "",
        deployedLink: "https://swagger.io/",
        image:"https://img.icons8.com/color/96/000000/cloud-function.png"
    }, 
    {
        id:15,
        points:"1",
        name: "Jenkins",
        description: " ",
        deployedLink: "https://www.jenkins.io/doc/",
        image:"https://img.icons8.com/color/96/000000/jenkins.png"
    }, 
    {
        id:16,
        points:"1",
        name: "Octopus",
        description: "",
        deployedLink: "https://octopus.com/",
        image:"https://img.icons8.com/color/96/000000/octopus.png"
    }, 

 
    {
        id:17,
        points:"1",
        name: "Docker",
        description: "",
        deployedLink: "https://www.docker.com/",
        image:"https://img.icons8.com/color/96/000000/docker.png"
    }, 
    {
        id:18,
        points:"1",
        name: "Powershell",
        description: "poweshell desc",
        deployedLink: "https://docs.microsoft.com/en-us/powershell/",
        image:"https://img.icons8.com/color/96/000000/powershell.png"
    }, 
    {
        id:19,
        points:"1",
        name: "IOT",
        description: "",
        deployedLink: "https://www.internetsociety.org/resources/doc/2015/iot-overview/",
        image:"https://img.icons8.com/fluency/96/000000/internet-of-things.png"
    }, 
    {
        id:20,
        points:"1",
        name: "Android",
        description: "",
        deployedLink: "https://developer.android.com/docs",
        image:"https://img.icons8.com/fluency/96/000000/android.png"
    }, 
    {
        id:21,
        points:"2",
        name: "Graphql",
        description: "",
        deployedLink: "https://graphql.org/",
        image:"https://img.icons8.com/color/96/000000/graphql.png"
    }, 
    {
        id:22,
        points:"0",
        name: "Golang",
        description: "",
        deployedLink: "https://golang.org/",
        image:"https://img.icons8.com/color/96/000000/golang.png"
    },
    
    

]

export default skills