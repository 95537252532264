const youtubeVideoes = [
    // {
    //     id:1,
    //     name: "Tree Data Structure - 7",
    //     description: "Tree Data Structure(Lecture-7):-Linked List Representation Of Binary Tree",
    //     deployedLink: "https://www.youtube.com/watch?v=Wdf6ByRq7lk",
    //     image:"https://i.ytimg.com/vi/Wdf6ByRq7lk/maxresdefault.jpg"
    // },

    {
        id:1,
        name: "All about JWT (Json Web Token)",
        description: "This playlist is about understanding JWT. In this playlist you can find JWT topic explained using article written on medium platform by me in three parts",
        deployedLink: "https://youtube.com/playlist?list=PLfjlOytYZqaq3JouK3MkM3hMb1APf3Y75",
        image:"https://i.ytimg.com/vi/pSg0iRrFJP8/hqdefault.jpg"
    }, 
    {
        id:2,
        name: "What is JWT",
        description: "JWT Article-1-part-1. All about JWT intro |  What is JWT? | Use of JWT for authentication",
        deployedLink: "https://youtu.be/qi5eBRQF4LI",
        image:"https://i.ytimg.com/vi/qi5eBRQF4LI/maxresdefault.jpg"
    }, 
    {
        id:3,
        name: "How to generate JWT token?",
        description: "Heart of JWT authcontroller | How to generate JWT token | egister, login endpoints | Cookie implementation | Securing cookie with multiple options",
        deployedLink: "https://youtu.be/qi5eBRQF4LI",
        image:"https://i.ytimg.com/vi/uRtWu_97Jy4/hqdefault.jpg"
    }, 
    // {
    //     id:3,
    //     name: "BigData",
    //     description: "BigData With Hadoop Framework(Lecture-4)-MapReduce",
    //     deployedLink: "https://www.youtube.com/watch?v=9PJVsyNJhMo",
    //     image:"https://i.ytimg.com/vi/9PJVsyNJhMo/maxresdefault.jpg"
    // }, 
]

export default youtubeVideoes