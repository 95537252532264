import React from 'react';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Element } from 'react-scroll'
import { ClickActionEvent } from '../../util/analytics'

import thoughtList from "./Data"

import OneThought from './OneThought'
 


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  cardHover: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "0 4px 8px 0 rgba(0,0,255,0.8), 0 6px 20px 0 rgba(0,0,255,0.8)",
    cursor: 'pointer',
    opacity:'0.7',
    transition: '0.2s linear'
    
  },
  cardMedia: {
    paddingTop: '22.25%',
    height: 70,
  },
  cardContent: {
    flexGrow: 1,
  },
}));


export default function MyThoughts() {
  
  const classes = useStyles();


  const [selectedItem, setSelectedItem] = React.useState(0)

  const PreviousQuote = (t)  =>{
    if(selectedItem==0)
      setSelectedItem(thoughtList.length-1)
    else
      setSelectedItem(selectedItem-1)
    ClickActionEvent("MyThoughts " + thoughtList[selectedItem].desc, 'my thoughts quotes section', 'my thoughts  previous quote clicked')
    
  }

  const NextQuote = (event)  =>{
    if(selectedItem==thoughtList.length-1)
      setSelectedItem(0)
    else
      setSelectedItem(selectedItem+1)
    ClickActionEvent("MyThoughts " + thoughtList[selectedItem].desc, 'my thoughts quotes section', 'my thoughts  next quote clicked')

  }

  return (
    <React.Fragment>
 
      <Element id='thoughts' name='thoughts' style={{backgroundColor:"#37BEB0"}}>

        <div className={classes.heroContent} style={{backgroundColor:"#37BEB0"}}>
          <Container maxWidth="lx">
            <Typography component="h1" variant="h3" align="center" style={{color:"#0c6170"}} gutterBottom>
              My Thoughts 🤔
            </Typography>
          </Container>

        </div>
        <Container align="center" className={classes.cardGrid} maxWidth="lx">
              <OneThought item={thoughtList[selectedItem]}/>
              <Button style={{backgroundColor:"#0c6170", margin:"10px", color:"#dbf5f0"}} size="small" color="primary" onClick={()=>PreviousQuote()}>
                Previous
              </Button>
              <Button style={{backgroundColor:"#0c6170", margin:"10px", color:"#dbf5f0"}} size="small" color="primary" onClick={()=>NextQuote()}>
                Next
              </Button>
        </Container>
      </Element>
 
    </React.Fragment>
  );
}
