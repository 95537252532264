import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Element } from "react-scroll";

import Info from "./info";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "22.25%",
    height: 70,
  },
  cardContent: {
    flexGrow: 1,
  },
}));

export default function ChefCorner() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Element id="contact" name="Chef-Corner">
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              CoffeeWithMe ☕
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              paragraph
            >
              Have a question or want to work together or if you just want to
              connect with me?
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <Info />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <img
                style={{ borderRadius: "50%", marginLeft: "8%" }}
                width="70%"
                height="70%"
                src={"javed_ooty.PNG"}
                alt={"item.title"}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>
    </React.Fragment>
  );
}
