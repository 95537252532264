import React from 'react';
import Button from '@material-ui/core/Button';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { Link } from 'react-router-dom'

import recipes from "./Data"
import RecipeModal from "./RecipeModal"
 
 

const myImage = {
    backgroundImage: "url(" + "https://cdn.pixabay.com/photo/2017/06/01/18/46/cook-2364221_1280.jpg" + ")",
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    height: '85vh',
    display: 'flex',  justifyContent:'center', alignItems:'center',      
  };

const ShareRecipe = (props) =>{
    const [open, setOpen] = React.useState(false);
    const [currentItem, setCurrentItem] = React.useState({name:"", ingredients:[], instructions:{english:[], hindi:[]}});
    let recipeId = 0
     recipeId = window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1
      );

    recipeId = isNaN(recipeId)?0:recipeId
    recipeId = (recipeId>=recipes.length || recipeId<0 )?0:recipeId
    // console.log("recipeId"+recipeId)

    const handleClickOpen = (item)  => {
        setCurrentItem({name:item.name, ingredients:item.ingredients, instructions:item.instructions})
        setOpen(true);
      };


    const handleClose = () => {
    setOpen(false);
    };
    
    return(
        <div>
                    <div style={myImage}>

                        <div style={{color:"#0d1230"}}   >
                            <RecipeModal modalStatus={open}  handleClose={handleClose} name={currentItem.name} ingredients={currentItem.ingredients} instructions={currentItem.instructions}/>
                            <Button style={{  marginTop:'10%', backgroundColor:"#0d1230"}}
                                size="large"
                                variant="contained"
                                color="primary"
                                onClick={()=>handleClickOpen(recipes[recipeId])}
                                endIcon={<TouchAppIcon />}
                                >
                                Click to see the recipe of  <span style={{color:"#2de3c1", marginLeft:"8px"}}> {recipes[recipeId].name}</span>
                            </Button>
                            <br/>
                            <Button style={{  marginTop:'10%', backgroundColor:"#0d1230"}}
                                size="large"
                                variant="contained"
                                color="primary"
                                component={Link} to="/"
                                endIcon={<TouchAppIcon />}
                                >
                                Explore all recipes from main/home page 
                            </Button>
                        </div>
                    </div>
                
                    
                    

        </div>
    )
}

export default ShareRecipe;