const IngredientsList = {

    paneer: {
        image: "https://media.istockphoto.com/photos/paneer-or-cottage-cheese-cube-close-up-slice-pieces-of-homemade-fresh-picture-id1183963198?s=612x612",
        name: "Paneer (पनीर)"
    },
    green_peas: {
        image: "https://img.icons8.com/color/48/000000/peas.png",
        name: "Green Peas (हरी मटर)"
    },
    tomato: {
        image: "https://img.icons8.com/emoji/48/000000/tomato-emoji.png",
        name: "Tomatoes (टमाटर)"
    },
    garlic: {
        image: "https://img.icons8.com/emoji/48/000000/garlic-emoji.png",
        name: "Garlic (लहसुन)"
    },
    ginger: {
        image: "https://img.icons8.com/fluency/48/000000/ginger.png",
        name: "Ginger (अदरक)"
    },
    onion: {
        image: "https://img.icons8.com/emoji/48/000000/onion-emoji.png",
        name: "Onion (प्याज)"
    },
    green_chilli: {
        image: "https://media.istockphoto.com/photos/green-chili-peppers-picture-id492596228?s=612x612",
        name: "Green Chilli (हरी मिर्च)"
    },
    cumin_seeds: {
        image: "https://media.istockphoto.com/photos/cumin-seeds-texture-background-caraway-spice-jeera-texture-picture-id1299931732?s=612x612",
        name: "Cumin Seeds (जीरा)"
    },
    cumin_seeds_p: {
        image: "https://media.istockphoto.com/photos/cumin-seeds-texture-background-caraway-spice-jeera-texture-picture-id1299931732?s=612x612",
        name: "Cumin Seeds Powder (जीरा पाउडर)"
    },
    gram_flour: {
        image: "https://media.istockphoto.com/photos/besan-gram-or-chickpea-flour-is-a-pulse-flour-made-from-a-variety-of-picture-id1024537560?s=612x612",
        name: "Gram flour (बेसन)"
    },
    sugar: {
        image: "https://img.icons8.com/office/16/000000/spoon-of-sugar.png",
        name: "Sugar (चीनी)"
    },
    red_chilli: {
        image: "https://img.icons8.com/emoji/48/000000/hot-pepper.png",
        name: "Red Chilli (लाल मिर्च)"
    },
    red_chilli_p: {
        image: "https://img.icons8.com/emoji/48/000000/hot-pepper.png",
        name: "Red Chilli Powder (लाल मिर्च पाउडर)"
    },
    turmeric: {
        image: "https://img.icons8.com/color/48/000000/tumeric.png",
        name: "Raw Turmeric  (कच्ची हल्दी)"
    },
    turmeric_p: {
        image: "https://img.icons8.com/color/48/000000/tumeric.png",
        name: "Turmeric Powder (हल्दी पाउडर)"
    },
    coriander: {
        image: "https://media.istockphoto.com/photos/indian-spice-coriander-powder-heap-selective-focus-picture-id901304036?s=612x612",
        name: "Coriander (धनिया )"
    },
    coriander_p: {
        image: "https://media.istockphoto.com/photos/indian-spice-coriander-powder-heap-selective-focus-picture-id901304036?s=612x612",
        name: "Coriander Powder (धनिया पाउडर)"
    },
    garam_masala_p: {
        image: "https://cdn.pixabay.com/photo/2020/01/13/19/46/garam-masala-4763363_960_720.jpg",
        name: "Garam Masala Powder (गरम मसाला पाउडर)"
    },
    coriander_leaves: {
        image: "https://media.istockphoto.com/photos/coriander-picture-id155098529?s=612x612",
        name: "Coriander Leaves (धनिए के पत्ते)"
    },
    refined_oil: {
        image: "https://image.shutterstock.com/image-vector/oil-drop-isolated-on-white-600w-475939882.jpg",
        name: "Refined Oil (रिफाइंड तेल)"
    },
    rajma: {
        image: "https://media.istockphoto.com/photos/red-kidney-beans-background-red-kidney-beans-texture-top-view-picture-id1155350783?s=612x612",
        name: "Rajma (राजमा)"
    },
    potato: {
        image: "https://img.icons8.com/emoji/50/000000/potato-emoji.png",
        name: "Potato (आलू)"
    },
    cinnamomum_tamala: {
        image: "https://media.istockphoto.com/photos/bay-leaf-isolated-on-white-background-picture-id1153503724?s=612x612",
        name: "Cinnamomum tamala (तेजपात)"
    },
    clove: {
        image: "https://media.istockphoto.com/photos/dried-clove-buds-isolated-on-white-background-picture-id1089394678?s=612x612",
        name: "Clove (लौंग)"
    },
    large_cardamom: {
        image: "https://media.istockphoto.com/photos/black-cardamom-picture-id180706862?s=612x612",
        name: "Large Cardamom (बडी इलायची)"
    },
    small_cardamom: {
        image: "https://media.istockphoto.com/photos/green-cardamom-seeds-isolated-on-white-picture-id182504055?s=612x612",
        name: "Small Cardamom (छोटी इलायची)"
    },
    salt: {
        image: "https://img.icons8.com/emoji/48/000000/salt-emoji.png",
        name: "Salt (नमक)"
    },
    ginger_garlic_p: {
        image: "https://media.istockphoto.com/photos/ginger-garlic-paste-picture-id186434573?s=612x612",
        name: "Ginger Garlic Paste (अदरक लहसुन का पेस्ट)"
    },
    black_pepper_p: {
        image: "https://media.istockphoto.com/photos/black-peppers-on-white-background-picture-id611874534?s=612x612",
        name: "Black Pepper Powder (काली मिर्च पाउडर)"
    },
    ghee: {
        image: "https://media.istockphoto.com/photos/ghee-or-clarified-butter-close-up-in-wooden-bowl-and-silver-spoon-picture-id857444908?s=612x612",
        name: "Ghee (घी)"
    },
    butter: {
        image: "https://img.icons8.com/color/48/000000/butter.png",
        name: "Butter (मक्खन)"
    },
    chicken: {
        image: "https://media.istockphoto.com/photos/whole-raw-chicken-picture-id614537764?s=612x612",
        name: "Chicken meat (मुर्गी का मांस)"
    },
    mustard_oil: {
        image: "https://media.istockphoto.com/photos/golden-mustard-with-oil-picture-id1014130744?s=612x612",
        name: "Mustard Oil (सर्सो टेल)"
    },
    kashmiri_chilli_powder: {
        image: "https://media.istockphoto.com/photos/kashmiri-chilli-powder-picture-id1178097527?s=612x612",
        name: "Kashmiri Chilli Powder (कश्मीरी मिर्च पाउडर)"
    },
    curd: {
        image: "https://media.istockphoto.com/photos/sour-cream-picture-id478216754?s=612x612",
        name: "Curd (दही)"
    },
    kasoori_methi: {
        image: "https://media.istockphoto.com/photos/kasoori-methi-or-dried-fenugreek-leaves-picture-id1251001755?s=612x612",
        name: "Kasoori Methi (कसूरी मेथी)"
    },
    cashew: {
        image: "https://media.istockphoto.com/photos/cashew-nuts-picture-id153730788?s=612x612",
        name: "Cashew (काजू)"
    },
    fresh_cream: {
        image: "https://media.istockphoto.com/photos/milk-or-yogurt-splash-isolated-picture-id1134003048?s=612x612",
        name: "Fresh Cream (ताजा मलाई)"
    },
    cinnamon: {
        image: "https://media.istockphoto.com/photos/cinnamon-sticks-on-white-picture-id1134101282?s=612x612",
        name: "Cinnamon (दालचीनी)"
    },
    tomato_ketchup: {
        image: "https://media.istockphoto.com/photos/tomato-sauce-picture-id1043912558?s=612x612",
        name: "Tomato Ketchup (टमाटर की चटनी)"
    },
    chat_masala: {
        image: "https://media.istockphoto.com/photos/chat-masala-or-chaat-masala-is-important-ingradient-of-panipuri-picture-id1253631969?s=612x612",
        name: "Chat Masala (चाट मसाला)"
    },
    soya: {
        image: "https://static.toiimg.com/photo/107437943.cms?s=612x612",
        name: "Soya Bean (सोयाबीन)"
    }
}

export default IngredientsList