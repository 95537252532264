import React from 'react';

import recipes from "./Data"
import RecipeModal from "./RecipeModal"
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import SingleRecipe from './SingleRecipe';

 
 
const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      
    },
    cardHover: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: "0 4px 8px 0 rgba(0,0,255,0.8), 0 6px 20px 0 rgba(0,0,255,0.8)",
      cursor: 'pointer',
      opacity:'0.7',
      transition: '0.2s linear'
      
    },
    cardMedia: {
      paddingTop: '22.25%',
      height: 70,
    },
    cardContent: {
      flexGrow: 1,
    },
  }));

// const myImage = {
//     backgroundImage: "url(" + "https://cdn.pixabay.com/photo/2017/06/01/18/46/cook-2364221_1280.jpg" + ")",
//     backgroundPosition: 'center',
//     backgroundSize: '100% 100%',
//     backgroundRepeat: 'no-repeat',
//     height: '90vh',
//     display: 'flex',  justifyContent:'center', alignItems:'center',      
//   };

const RecipePage = (props) =>{
    const [open, setOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState('')
    const [currentItem, setCurrentItem] = React.useState({name:"", ingredients:[], instructions:{english:[], hindi:[]}});
    let recipeId = 0
     recipeId = window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1
      );

    recipeId = isNaN(recipeId)?0:recipeId
    recipeId = (recipeId>=recipes.length || recipeId<0 )?0:recipeId
    console.log("recipeId"+recipeId)
    const classes = useStyles();

    const handleClickOpen = (item)  => {
        setCurrentItem({name:item.name, ingredients:item.ingredients, instructions:item.instructions})
        setOpen(true);
      };

      const MouseOver = (t)  =>{
        setSelectedItem(t)
        console.log(selectedItem)
        
      }
    
      const MouseOut = (event)  =>{
        setSelectedItem('')
      }

    const handleClose = () => {
    setOpen(false);
    };
    
    return (
      <div>
        {/* <div style={myImage}> */}
          <div style={{ color: "#0d1230" }}>
            <Container className={classes.cardGrid} maxWidth="md">
              <Grid container spacing={4}>
                <RecipeModal
                  modalStatus={open}
                  handleClose={handleClose}
                  name={currentItem.name}
                  ingredients={currentItem.ingredients}
                  instructions={currentItem.instructions}
                />
                {recipes.map((item) => (
                  <Grid item key={item.id} xs={12} sm={6} md={4}>
                    <Card
                      onClick={() => handleClickOpen(item)}
                      className={
                        item.name == selectedItem
                          ? classes.cardHover
                          : classes.card
                      }
                      onMouseOver={() => MouseOver(item.name)}
                      onMouseOut={MouseOut}
                    >
                      <SingleRecipe
                        name={item.name}
                        id={item.id}
                        noIngredients={item.ingredients.length}
                        image={item.image}
                        description={item.description}
                      />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
        {/* </div> */}
      </div>
    );
}

export default RecipePage;