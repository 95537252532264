const Books = [
    {
        id:1,
        originalTitle : "Wings of Fire by A. P. J. Abdul Kalam and Arun Tiwari",
        description: `Book by A. P. J. Abdul Kalam and Arun Tiwari`,
        original:"https://upload.wikimedia.org/wikipedia/en/3/3a/Wings_of_Fire_by_A_P_J_Abdul_Kalam_Book_Cover.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    },
    {
        id:2,
        originalTitle : "No Rules Rules by Reed Hastings",
        description: "Netflix and the Culture of Reinvention : Hastings, Reed, Meyer, Erin",
        original:"https://images-na.ssl-images-amazon.com/images/I/81Dg7P7KFFL.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    }, 
    {
        id:3,
        originalTitle: "The Almanack of Naval Ravikant by Eric Jorgenson",
        description: "A Guide to Wealth and Happiness",
        original:"https://images-na.ssl-images-amazon.com/images/I/61VbL0FspqL.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    }, 
    {
        id:4,
        originalTitle: "Why I Stopped Wearing My Socks by Alok Kejriwal",
        description: "Why I Stopped Wearing My Socks by Alok Kejriwal",
        original:"https://images-na.ssl-images-amazon.com/images/I/41wBj6pa9SL.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    }, 
    {
        id:5,
        originalTitle: "The Psychology of Money by Morgan Housel",
        description: "Timeless lessons on wealth, greed, and happiness",
        original:"https://images-na.ssl-images-amazon.com/images/I/81Lb75rUhLL.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    }, 
    {
        id:6,
        originalTitle: "Mindset by Carol Dweck",
        description: "Mindset: Changing The Way You think To Fulfil Your Potential",
        original:"https://images-na.ssl-images-amazon.com/images/I/41r55iXAlQL._SX317_BO1,204,203,200_.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    }, 
    {
        id:7,
        originalTitle: "The 7 Habits of Highly Effective People by R. Stephen Covey",
        description: "The 7 Habits of Highly Effective People",
        original:"https://images-na.ssl-images-amazon.com/images/I/817xk9KvJbL.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    },
    {
        id:8,
        originalTitle: "ReWork by David Heinemeier Hansson & Jason Fried",
        description: "ReWork: Change the Way You Work Forever",
        original:"https://images-na.ssl-images-amazon.com/images/I/61GFc+k-5PL.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    }, 
    {
        id:9,
        originalTitle: "Deep Work by Cal Newport",
        description: "Deep Work: Rules for Focused Success in a Distracted World",
        original:"https://images-na.ssl-images-amazon.com/images/I/814IUt6WOKL.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    }, 
    {
        id:10,
        originalTitle: "The Miracle Morning by Hal Elrod",
        description: "The Miracle Morning: The 6 Habits That Will Transform Your Life Before 8AM",
        original:"https://images-na.ssl-images-amazon.com/images/I/61DyDYXPhSL.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    }, 
    {
        id:11,
        originalTitle: "LOSE FAT, GET FITTR by Jitendra Chouksey ",
        description: "LOSE FAT, GET FITTR: THE SIMPLE SCIENCE OF STAYING HEALTHY FOR LIFE",
        original:"https://images-na.ssl-images-amazon.com/images/I/71CTiPDWSrL.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    }, 
    {
        id:12,
        originalTitle: "You Can Win by Shiv Khera",
        description: "You Can Win: A Step-by-Step Tool for Top Achievers",
        original:"https://images-na.ssl-images-amazon.com/images/I/81zgGfR5TtL.jpg",
        originalHeight  :"300",
        originalWidth  : "70"
    }, 
]

export default Books