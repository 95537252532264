const quotesList= [

    {
        id:1, name:"Javedinfinite(me)", 
        quote:"Maturity is when you have the courage to doubt your beliefs and keep analysing whether these beliefs are still worth going on with or needs to be changed or stop believing them...", 
        image:"https://media-exp1.licdn.com/dms/image/C5103AQH9FZ7t5e_Ckw/profile-displayphoto-shrink_800_800/0/1542788077159?e=1638403200&v=beta&t=8SHYXccReLaaSDlpCTiHSB1jgR4LqBtlemdAQNOO_oU"
    },

    {
        id:2, name:"Shahrukh Khan", 
        quote:" I tell everyone, first get rich then become a philosopher.", 
        image:"https://m.media-amazon.com/images/M/MV5BZDk1ZmU0NGYtMzQ2Yi00N2NjLTkyNWEtZWE2NTU4NTJiZGUzXkEyXkFqcGdeQXVyMTExNDQ2MTI@._V1_UY1200_CR107,0,630,1200_AL_.jpg"
    },

    {
        id:3, name:"APJ Abdul Kalam", 
        quote:" You have to dream before your dreams can come true", 
        image:"https://cdn.britannica.com/56/148856-004-2F59E2D9/APJ-2008.jpg"
    },

    {
        id:3, name:"APJ Abdul Kalam", 
        quote:" Ego is the only requirement to destroy any relationship, so be a bigger person skip the E and let it GO", 
        image:"https://cdn.britannica.com/56/148856-004-2F59E2D9/APJ-2008.jpg"
    },

    {
        id:4, name:"Naval Ravikant", 
        quote:" I can't be jealous of anyone's life if I am not willing to take their entire life as mine", 
        image:"https://upload.wikimedia.org/wikipedia/commons/a/aa/Naval2019.png"
    },

    {
        id:4, name:"Naval Ravikant", 
        quote:" Anger is a hot coal that you hold in your hand while waiting to throw it at someone else (Buddhist saying)", 
        image:"https://upload.wikimedia.org/wikipedia/commons/a/aa/Naval2019.png"
    },
    {
        id:4, name:"Naval Ravikant", 
        quote:` Seek wealth, not money or status. Wealth is having assets that earn while you sleep. Money is how we transfer time
                and wealth. Status is your place in the social hierarchy`, 
        image:"https://upload.wikimedia.org/wikipedia/commons/a/aa/Naval2019.png"
    },

    {
        id:5, name:"Ayushmann Khurana", 
        quote:` Us Gali ne ye sun kar sabr kar liya......
        Ki Jaane wale yaha ke the hi nahi.... (acceptance that the girl was in your life for only that moment, it will become easy to forget her... )
        `, 
        image:"https://theshillongtimes.com/wp-content/uploads/2021/01/Ayushmann-Khurrana-Image3.jpg"
    },

    {
        id:6, name:"Zakir Khan", 
        quote:` Ki wo titli ki tarah aayi aur zindagi ko baag kar gayi, mere jitne bhi naapaak the iraade unhe bhi paak kar gayi.`, 
        image:"https://customercarelife.com/wp-content/uploads/2020/07/Zakir-Khan.jpg"
    },

    {
        id:6, name:"Unknown", 
        quote:` Yesterday I was clever , So I wanted to change the world.
        Today I am wise so I am changing  myself
        .`, 
        image:"https://image.shutterstock.com/image-vector/male-face-silhouette-icon-man-260nw-1165858396.jpg   "
    },

    
    
    
    
        
    

]

export default quotesList
