import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonAppBar from './components/app-bar/Main'
import { Switch, Route, HashRouter, BrowserRouter as Router } from 'react-router-dom';

import Home from './components/Home/Home'
import Projects from './components/Projects'
import Footer from './components/Footer'
import Error from './components/Error'
import ShareRecipe from './components/ChefCorner/ShareRecipe';
import { isLocalDevelopment } from './constants/environment';
import RecipePage from './components/ChefCorner/RecipePage';

import ReactGA from "react-ga4";
const MEASUREMENTID = "G-3V4GDN613C"; // OUR_TRACKING_ID

ReactGA.initialize(MEASUREMENTID, {
  // debug: true, // Enable debug mode
});



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));


function App() {
  const classes = useStyles();
  if(!isLocalDevelopment())ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "jd home page visited" });

  return (
    <HashRouter>
   
    <div className={classes.root}>
      <ButtonAppBar  />
      <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/projects" component={Projects} />
              <Route path="/share" component={ShareRecipe} />
              <Route path="/recipes" component={RecipePage} />

              <Route component={Error}/>
         
    </Switch>

  </div>
  <Footer title="We can connect here" description="Made with love"/>
  </HashRouter>
  );
}

export default App