import IconDashboard from '@material-ui/icons/Dashboard'
import IconPeople from '@material-ui/icons/People'
import IconBarChart from '@material-ui/icons/BarChart'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'

import HomeIcon from '@material-ui/icons/Home';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PhotoIcon from '@material-ui/icons/Photo';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';


 const appMenuItems = [
    {
      name: 'Home',
      link: 'home',
      Icon: HomeIcon,
    },
    {
      name: 'CoffeeWithMe',
      link: 'contact',
      Icon: FreeBreakfastIcon,
    },
    {
      name: 'Articles',
      link: 'articles',
      Icon: TextFormatIcon,
    },
    {
      name: 'Projects',
      link: 'projects',
      Icon: IconDashboard,
    },
    {
      name: 'Skills',
      link: 'skills',
      Icon: FormatListNumberedRtlIcon,
    },

    {
      name: 'Youtube',
      link: 'youtube',
      Icon: YouTubeIcon,
    },
    {
      name: 'Gallery',
      link: 'gallery',
      Icon: PhotoIcon,
    },
    {
      name: 'Chef-Corner',
      link: 'food',
      Icon: FastfoodIcon,
    },
    {
      name: 'Books I Read',
      link: 'books',
      Icon: LibraryBooksIcon, 
    },

    {
      name: 'More',
      // Icon: ExpandMoreIcon,
      items: [
        {
          name: 'My Fav Quotes',
          link: 'quotes',
          Icon: FormatQuoteIcon, 
        },
        {
          name: 'My Thoughts',
          link: 'thoughts',
          Icon: EmojiObjectsIcon, 
        },


 
      ],
    },

  ]

  export default appMenuItems