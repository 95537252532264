const projects = [
    {
        id:1,
        name: "Health Online (❌ Mobile ui)",
        description: "This is a Static UI template made for desktop, an online booking system",
        deployedLink: "https://javedinfinite.github.io/health-online/#/bookings",
        codeLink: "https://github.com/javedinfinite/health-online",
        image:"healthOnline.PNG"
    },
    {
        id:2,
        name: "CSV Uploader (❌ Mobile ui)",
        description: "This is a fully functional CSV uploader backed by node.js. You can preview, upload, view all with server side pagination.",
        deployedLink: "https://javedinfinite.github.io/safe-csv-uploader/#/",
        codeLink: "https://github.com/javedinfinite/safe-csv-uploader",
        image:"CsvUploader.PNG"
    },
    {
        id:3,
        name: "Task App",
        description: "An application for keeping a note of list tasks using firebase and react. It's a real time app.",
        deployedLink: "https://javedinfinite.github.io/dentira-task-app/",
        codeLink: "https://github.com/javedinfinite/dentira-task-app",
        image:"taskApp.PNG"
    },
    {
        id:4,
        name: "Furniture Shop",
        description: "A website to showcase different furniture designs",
        deployedLink: "https://rakfurnhi.com/#/",
        codeLink: "https://github.com/javedinfinite/furniture_shop",
        image:"furnitureShop.PNG"
    },
    {
        id:5,
        name: "Friends List",
        description: "A website to list down all friends and their friends. A little slow to load as it's client,server and db are deployed on free infrastructures. You will find all features working only for page-1 due to less data present in DB",
        deployedLink: "https://javedinfinite.github.io/code_mobile_frontend/",
        codeLink: "https://github.com/javedinfinite/code_mobile_frontend",
        image:"friendsList.PNG"
    },
    {
        id:6,
        name: "Youtube API POC(❌ Mobile ui)",
        description: "This is to just list down my youtube channel playlists using youtube API. Here you can find my all youtube videos and watch them.",
        deployedLink: "https://javedinfinite.github.io/friends_app/#/apj_academy",
        codeLink: "https://github.com/javedinfinite/friends_app",
        image:"youtubeApi.PNG"
    },
    {
        id:7,
        name: "Bloglist",
        description: "This is a bloglist app with nested comment feature on each blog you are reading. I have used indexed DB to store all data. This means all data will be stored only in your browser and only you will be able to access them. Initially you will see no blog. Start by creating your first blog by pressing on + button.",
        deployedLink: "https://javedinfinite.github.io/blog-list/#/",
        codeLink: "https://github.com/javedinfinite/blog-list",
        image:"bloglistAppa.PNG"
    },
    {
        id:8,
        name: "Product List",
        description: "This is a product list react app using ExchangeRate API. This is to show current price of a product in USD for a given product price in Rupee.",
        deployedLink: "https://javedinfinite.github.io/product-currencywise/#/",
        codeLink: "https://github.com/javedinfinite/product-currencywise",
        image:"productList.PNG"
    },
]

export default projects