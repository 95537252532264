import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Element } from 'react-scroll'
import YouTubeIcon from '@material-ui/icons/YouTube';
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { ClickActionEvent } from '../../util/analytics'

import youtubeVideoes from "./Data"
 


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  cardHover: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "0 4px 8px 0 rgba(0,0,255,0.8), 0 6px 20px 0 rgba(0,0,255,0.8)",
    cursor: 'pointer',
    opacity:'0.7',
    transition: '0.2s linear'
    
  },
  cardMedia: {
    paddingTop: '22.25%',
    height: 70,
  },
  cardContent: {
    flexGrow: 1,
  },
}));


export default function Youtube() {
  
  const classes = useStyles();

  const [selectedItem, setSelectedItem] = React.useState('')

  const MouseOver = (t)  =>{
    setSelectedItem(t)
    console.log(selectedItem)
    
  }

  const MouseOut = (event)  =>{
    setSelectedItem('')
  }

  return (
    <React.Fragment>
 
      <Element id='youtube' name='youtube' style={{backgroundColor:"#dbf5f0"}}>

        <div className={classes.heroContent} style={{backgroundColor:"#dbf5f0"}}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
              My Youtube Videos <YouTubeIcon fontSize="large" style={{color:"red"}}/>
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              Hey! geeks take it easy 😃, these videos are mix of college days(with limited knowledge/resource) and recent creativity(based on professional experiences).
              I know there is always room for improvement, you will see the improvement in the content if you compare old and latest viodeos.
              Old videos are kept to keep alive the college day's Javed and new videos is to represent latest version of Javed. Something new is alwas cooking (but slowly)...🐻 Bear with me...
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            {youtubeVideoes.map((item) => (
              <Grid item key={item.id} xs={12} sm={6} md={4}>
               <Link href={item.deployedLink} target="_blank" onClick={()=>{ClickActionEvent('Youtube '+item.name, 'youtube section', 'youtube link clicked')}}> 
                <Card style={{backgroundColor:"#a4e5e0"}}  className={item.name==selectedItem?classes.cardHover:classes.card}  onMouseOver={()=>MouseOver(item.name)} onMouseOut={MouseOut}>
                  
                  <CardMedia
                    className={classes.cardMedia}
                    image={process.env.PUBLIC_URL + item.image}
                    title={item.name}
                  />
                  
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {item.name} <TouchAppIcon/>
                    </Typography>
                    <Typography>
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
          <p> <a href="https://www.youtube.com/@javedinfinite" target="_blank">Check out all the videos on my Youtube Channel</a></p>
        </Container>
      </Element>
 
    </React.Fragment>
  );
}
