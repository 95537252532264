const fedbackTextEnglish = "If you found this recipe instruction helpful then please share your experience on any of social media like twitter or instagram, Id: javedinfinite, I will love to hear 🙂 - Javed."
const fedbackTextHindi = "यदि आपको यह रेसिपी निर्देश उपयोगी लगा तो कृपया अपना अनुभव किसी भी सोशल मीडिया जैसे ट्विटर या इंस्टाग्राम पर साझा करें, आईडी: javedinfinite, मुझे सुनना अच्छा लगेगा 🙂 - जावेद"
const recipes  = [
    {
        id:1,
        key: 'matarPaneer',
        name: "Matar Paneer",
        description: "Matar Paneer, a veg dish, delicious.........",
        image: 'matar_paneer.PNG',
        ingredients:[
            {code:"paneer", quantity:"200g" }, 
            {quantity:"1.5 cup", code:"green_peas"}, 
            {  quantity:"3-4", code:"tomato"},
            {  quantity:"7-8 cloves", code:"garlic"},
            {  quantity:"small piece", code:"ginger"},
            {  quantity:"2-3", code:"onion"},
            {  quantity:"3", code:"green_chilli"},
            {  quantity:"1 tsp", code:"cumin_seeds"},
            { quantity:"1 tsp", code:"gram_flour"},
            { quantity:"1/2 tsp", code:"sugar"},
            { quantity:"1/2 tsp", code:"red_chilli_p"},
            {  quantity:"1/2 tsp", code:"turmeric_p"},
            {  quantity:"2 tsp", code:"coriander_p"},
            {  quantity:"1/2 tsp", code:"cumin_seeds_p"},
            { quantity:"1 tsp", code:"garam_masala_p"},
            {  quantity:"as needed", code:"coriander_leaves"},
            { quantity:"2 tsp + 2 tsp", code:"refined_oil"},
        ],
        instructions:{
            english:[ 
            `1. Take 3-4 tomatoes, 7-8 garlic, small piece of ginger, 2-3 onions, 3 green chillies, chop them roughly not finely`,
            `2. Take pan and add oil a little bit (2 spoon), add chopped onion, ginger, garlic, green chilli, 
                roast them until the color becomes translucent`,
            `3. Now add roughly chopped tomatoes and salt. Cook it until the oil gets separated. Keep stirring (5 minutes). Add a little bit of water and cover it for 5 minutes. 	Now take the pan off the gas and let it cool (this is our roasted base of gravy)`,
            `4. Take 200 grams paneer, make small cubes of them, take Green Peas 1.5 cup`,
            `5. Now take the roasted base gravy and grind it in a mixture with a little bit of water in it.
                Start slowly-slowly (Show some love 🙂 ).`,
            `6. Take another pan, add oil(2 spoon) , add Cumin Seeds, add 1/2 tsp gram flour and red chilli powder, turmeric powder. roast it a little bit, add paste of base gravy, cook it until oil starts separating. Cook it using a cover . Meanwhile add 2 tsp coriander powder, 1/2 tsp Cumin Seeds Powder,
                mix it. Add a little bit of sugar 1/2 tsp. Now add the Green Peas, mix it. Cook it for 5 minutes with a cover. Now you will see oil gets separated. Add Garam Masala Powder, mix it. Add water for gravy. Now add paneer cubes, cook it with a cover (important).`,
            `7. Add coriander leaves, chopped ginger a little bit and it is ready to serve, enjoy. ${fedbackTextEnglish}`
        
            ],
            hindi:[ 
                `1. 3-4 टमाटर, 7-8 लहसुन, अदरक का छोटा टुकड़ा, 2-3 प्याज़, 3 हरी मिर्च लें, इन्हें बारीक नहीं काट कर तैयार कर लीजिए`,
                `2. पैन लें और उसमें थोड़ा सा तेल (2 चम्मच) डालें, कटा हुआ प्याज, अदरक, लहसुन, हरी मिर्च डालें
                    इन्हें तब तक भूनिये जब तक कि रंग पारभासी न हो जाए`,
                `3. अब इसमें बारीक कटे टमाटर और नमक डालें। इसे तब तक पकाएं जब तक कि तेल अलग न हो जाए। हिलाते रहें (5 मिनट)। थोड़ा सा पानी डालकर 5 मिनट के लिए ढककर रख दें। - अब पैन को गैस से उतारकर ठंडा होने दें (यह हमारी ग्रेवी का भुना हुआ बेस है)`,
                `4. 200 ग्राम पनीर लेकर उसके छोटे छोटे टुकड़े कर लीजिये, मटर के दाने 1.5 कप लीजिये`,
                `5. अब भुनी हुई बेसन की ग्रेवी लें और उसमें थोड़ा सा पानी मिलाकर मिश्रण में पीस लें.
                    धीरे-धीरे शुरू करो (थोड़ा प्यार दिखाओ 🙂 ).`,
                `6. एक और पैन लें, तेल (2 चम्मच) डालें, जीरा डालें, 1/2 छोटा चम्मच बेसन और लाल मिर्च पाउडर, हल्दी पाउडर डालें। थोड़ा सा भूनिये, बेसन की ग्रेवी का पेस्ट डाल कर, तेल अलग होने तक पका लीजिये. इसे ढककर पकाएं। इस बीच 2 टीस्पून धनिया पाउडर, 1/2 टीस्पून जीरा पाउडर डालें,
                    इसे मिलाओ। थोडी़ सी चीनी 1/2 छोटा चम्मच डालें। अब हरी मटर डालें, मिलाएँ। इसे ढककर 5 मिनट तक पकाएं। अब आप देखेंगे कि तेल अलग हो गया है। गरम मसाला पावडर डालें, मिलाएँ। ग्रेवी के लिए पानी डालें। अब इसमें पनीर के टुकड़े डालिये, इसे ढककर (महत्वपूर्ण) पकाइये`,
                `7. हरा धनिया, कटा हुआ अदरक थोड़ा सा डालें और यह परोसने के लिए तैयार है, आनंद लें। ${fedbackTextHindi}`
                ]
        }
    },
    {
        id:2,
        key: 'rajma',
        name: "Rajma",
        description: "Rajma, a veg dish, delicious, Rajma and Rice...kya he kahne...",
        image: 'raajma.PNG',
        ingredients:[
            { quantity:"1 bowl", code:"rajma"}, 
            { quantity:"2", code:"potato"}, 
            { quantity:"6", code:"tomato"},
            {  quantity:"1",code:"cinnamomum_tamala"},
            { quantity:"4", code:"clove"},
            {  quantity:"1", code:"large_cardamom"},
            {  quantity:"as needed", code:"salt"},
            {  quantity:"1-2", code:"onion"},
            { quantity:"2", code:"green_chilli"},
            {  quantity:"1 tsp", code:"ginger_garlic_p"},
            {  quantity:"1/2 tsp", code:"turmeric_p"},
            { quantity:"1.5 tsp", code:"coriander_p"},
            {  quantity:"1/2 tsp", code:"red_chilli_p"},
            {  quantity:"1/2 tsp", code:"black_pepper_p"},
            {  quantity:"2 tsp", code:"ghee"},
            {  quantity:"1/4 tsp", code:"cumin_seeds_p"},
            {  quantity:"2 cubes", code:"butter"},
            {  quantity:"as needed", code:"coriander_leaves"},
        ],
        instructions:{
        english:[
            `1. Take 1 bowl rajma(~200g) Keep rajma in water for 12 hours or keep fresh Raajma for 1 hour in hot water,
                Wash potato properly`,
            `2. Take 1 cinnamomum tamalatejpata, 4 cloves and 1 bari large cardamom ,1 tsp saltin water to boil with rajma`,
            `3. Boil rajma and potato in a cooker with water 1/2 inch above than raajma. First keep boiling on high flame till whistle is about to start, then make it low flame for 15 to 20 minutes. Total 25 minutes. Or simply keep it 6 to 8 whistle.`,
            `4. Take 6 small size tomatoes, 2 green chilli,1 spoon ginger & garlic paste. Make paste of these using a mixture. `,
            `5. Prepare mashed potato. Take 1-2 onions and chop them finely`,
            `6. Prepare powder masale: ½ tsp turmeric, 1.5 tsp coriander powder,½ tsp red chilli powder, ½ tsp black pepper powder`,
            `7. Take 2 tsp desi ghee or oil in a pan, little jeera, after jeera becomes red in color then add onion fines in the pan and cook untill onion becomes brown , then pour a little water so that onion gets cooked well and getting structure of paste. Now add tomato paste and add salt, do fry untill tomato starts leaving oil. All water will go away. Now add our powder masala, fry it for 2 to 3 minutes.`,
            `8. Add this masala to our Rajma which is in pressure cooker. mix it then add garam masala ,cumin seeds poweder and mashed potato. Then add butter. Cook it for one whistle.`,
            `9. Let the cooker cool and then add dhania leaf and it is ready to serve. ${fedbackTextEnglish}`
            ],
        hindi:[
            `1. 1 कटोरी राजमा (~ 200 ग्राम) लें, राजमा को 12 घंटे पानी में रखें या ताजा राजमा 1 घंटे गर्म पानी में रखें,
                आलू को अच्छे से धो लीजिये`,
            `2. राजमा के साथ उबालने के लिए 1 दालचीनी तमलतेजपाटा, 4 लौंग और 1 बड़ी इलायची, 1 छोटा चम्मच नमक का पानी लें।`,
            `3. राजमा और आलू को कुकर में राजमा से 1/2 इंच ऊपर पानी डालकर उबाल लीजिये. सबसे पहले तेज आंच पर सीटी आने तक उबालते रहें, फिर इसे धीमी आंच पर 15 से 20 मिनट के लिए कर दें। कुल 25 मिनट। या बस ६ से ८ सीटी लगा कर रख दें।`,
            `4. 6 छोटे आकार के टमाटर, 2 हरी मिर्च, 1 चम्मच अदरक और लहसुन का पेस्ट लें। मिश्रण की सहायता से इनका पेस्ट बना लें। `,
            `5. मैश किया हुआ आलू तैयार करें। १-२ प्याज लें और उन्हें बारीक काट लें `,
            `6. पाउडर मसाला तैयार करें: ½ छोटा चम्मच हल्दी, 1.5 छोटा चम्मच धनिया पाउडर, ½ छोटा चम्मच लाल मिर्च पाउडर, ½ छोटा चम्मच काली मिर्च पाउडर`,
            `7. एक कढ़ाई में 2 छोटी चम्मच देसी घी या तेल, थोड़ा जीरा, जीरा लाल होने के बाद, पैन में प्याज बारीक डालिये और प्याज को ब्राउन होने तक पकाइये, फिर थोड़ा सा पानी डालिये ताकि प्याज अच्छी तरह से पक जाये और पेस्ट बन जाये . अब टमाटर का पेस्ट डाल कर नमक डाल कर तब तक भूनिये जब तक टमाटर तेल ना छोड़ने लगे. सारा पानी निकल जाएगा। अब हमारा पाउडर मसाला डाल कर 2 से 3 मिनिट तक भूनिये.`,
            `8. इस मसाले को हमारे राजमा में डाल दीजिये जो कि प्रेशर कुकर में है. मिक्स करें फिर गरम मसाला, जीरा पाउडर और मैश किए हुए आलू डालें। फिर मक्खन डालें। एक सीटी आने तक पकाएं।`,
            `9. कुकर को ठंडा होने दें और फिर धनिया पत्ता डालें और यह परोसने के लिए तैयार है। ${fedbackTextHindi}`
        ]
        }
    },
    {
        id:3,
        key: 'butterChicken',
        name: "Butter Chicken",
        description: "Butter Chicken dhaba style, a creamy and full of richness of taste",
        image: 'Butter_chicken.PNG',
        ingredients:[
            { quantity:"1 kg", code:"chicken"}, 
            { quantity:"3 tbsp", code:"mustard_oil"}, 
            {  quantity:"1 tbsp", code:"gram_flour"},
            {  quantity:"1-3 tbsp",code:"kashmiri_chilli_powder"},
            {  quantity:"10 tbsp", code:"curd"},
            {  quantity:"a little", code:"kasoori_methi"},
            {  quantity:"as needed", code:"salt"},
            {  quantity:"1 + 1 tsp", code:"garam_masala_p"},
            {  quantity:"5", code:"tomato"},
            { quantity:"3", code:"onion"},
            {  quantity:"2 pieces", code:"red_chilli"},
            {  quantity:"1 tbsp", code:"ginger_garlic_p"},
            {  quantity:"3 ", code:"ginger"},
            {  quantity:"5 ", code:"garlic"},
            {  quantity:"6 cubes", code:"butter"},
            { quantity:"10 whole pieces", code:"cashew"},
            { quantity:"4-5 tsp", code:"fresh_cream"},
            {  quantity:"as needed", code:"coriander_leaves"},
            {  quantity:"1", code:"small_cardamom"},
            {  quantity:"1",code:"cinnamomum_tamala"},
            {  quantity:"1",code:"cinnamon"},
            {  quantity:"1/2 tsp", code:"turmeric_p"},
            {  quantity:"1/2 tsp", code:"tomato_ketchup"},
            {  quantity:"1 tbsp", code:"chat_masala"},
        ],
        instructions:{
            english:[
            `1. Take a pan, add 3 tbsp mustard oil, when it gets hot, turn off the flame and add 1 tbsp gram flour, stir it till smell comes out (only few seconds), add kashmiri red chilli 1 tbsp, mix them and our gram flour base is ready`,
            `2. Marination process: Take chicken(add cuts to the chicken pieces), take 6 tbsp curd, add our ready gram flour base to it, add kasoori methi, 1 tbsp adrakh ginger & garlic paste, 1 tbsp chaat masala, add 1 tbsp salt, add garam masala to it, mix them all properly and Keep it in cool place for 30 minutes,`,
            `3. Gravy requirements : 4-5 tomatoes, 2 onions, make slices of it in big pieces, 2 dried red chilli, 5 garlic pieces, 3 small pieces of ginger, 2 butter cube, cashews 10, 4-5 tbsp malai or fresh cream, now dhania stems required to make gravy thick.`,
            `4. Take a pan, add 2 tbsp oil, 2 cubes of butter, now add slices of onions,  break the dried chillies, roast it a little, add ginger and garlic pieces, 3 minutes cooking, now add kaju.
                The purpose of roasting is to make them soft. Now add big pieces of tomato, and add 1 tbsp salt. Now cook until tomato gets soft, 
                we can add little water to help tomatoes in getting soft, 	cook it with a cover, after each 1 minutes stir it. Repeat this for 3 to 4 times with water if required.`,
            `5. Now all is soften, add coriander stems, cover it , cook it, Now keep it to get cooled. Once gets cool, use mixture to mix and grind it and make the fine paste.
				This is our tomatomix paste,`,
            `6. Roasting the chicken: take a pan, add oil 3-4 tbsp, make sure the temprature of pan is very high before adding chicken to it. Flip it in few moments. Cover it let it cook, 
                keep checking. After 6 minutes, chicken will get roasted. Now  take chciken out. From the remaining oil , remove over cooked and we will use this oil.`,
            `7. Final cooking: Add the remaining oil in pan, add 3 small cardamom, cinnamomum tamala, cinnamon, haldi powder, 2 tsp kashmiri red chilli powder , quickly roat it and add our tomatomix paste, 
                add required water, add 2-3 spoon tomato ketchup, Now add 2 cubes of butter, after it add chicken, and cook it on low flame, let it cook for 5 minutes,`,
            `8. roast kassori methi, crush it, add it to the chicken, add 1 tbsp garam masala, add fresh cream (now stop cooking), Done. ${fedbackTextEnglish}`,
            ],
        hindi:[
            
            `1. एक पैन लें, उसमें 3 टेबलस्पून सरसों का तेल डालें, गैस बंद कर दें और 1 टेबलस्पून बेसन डालें, इसे तब तक चलाएं जब तक कि महक न आ जाए (केवल कुछ सेकंड), कश्मीरी लाल मिर्च 1 टेबलस्पून डालें, मिलाएँ और हमारे चने आटे का बेस तैयार है`,
            `2. मैरिनेशन प्रक्रिया: चिकन लें (चिकन के टुकड़ों में कटौती करें), 6 टेबलस्पून दही लें, इसमें हमारा तैयार बेसन बेस डालें, कसूरी मेथी, 1 टेबलस्पून अदरक और लहसुन का पेस्ट, 1 टेबलस्पून चाट मसाला, 1 टेबलस्पून नमक डालें, इसमें गरम मसाला डालिये, इन सबको अच्छे से मिलाइये और 30 मिनिट के लिये ठंडी जगह पर रख दीजिये`,
            `3. ग्रेवी की आवश्यकता: 4-5 टमाटर, 2 प्याज, इसके बड़े टुकड़े कर लें, 2 सूखी लाल मिर्च, 5 लहसुन के टुकड़े, 3 छोटे अदरक के टुकड़े, 2 बटर क्यूब, काजू 10, 4-5 टेबल स्पून मलाई या फ्रेश क्रीम, ग्रेवी को गाढ़ा बनाने के लिए अब धनिया के डंठल की जरूरत है।`,
            `4. एक पैन लें, उसमें 2 टेबल स्पून तेल, 2 क्यूब मक्खन डालें, अब प्याज के स्लाइस डालें, सूखी मिर्च को तोड़ें, थोड़ा सा भूनें, अदरक और लहसुन के टुकड़े डालें, 3 मिनट पकाएँ, अब काजू डालें।
                भूनने का उद्देश्य उन्हें नरम बनाना है। अब टमाटर के बड़े टुकड़े डालें और 1 टेबल स्पून नमक डालें। अब टमाटर के नरम होने तक पकाये,
                हम टमाटर को नरम होने में मदद करने के लिए थोड़ा पानी डाल सकते हैं, इसे एक ढक्कन के साथ पका सकते हैं, प्रत्येक 1 मिनट के बाद इसे हिलाएं। आवश्यकता पड़ने पर इसे पानी के साथ ३ से ४ बार दोहराएं।`,
            `5. अब सब नरम हो गया है, धनिया के डंठल डाल कर ढक कर पका लीजिये, अब इसे ठंडा होने के लिये रख दीजिये. जब यह ठंडा हो जाए तो मिश्रण को मिक्स करके पीस लें और बारीक पेस्ट बना लें।
                यह हमारा टोमैटोमिक्स पेस्ट है,`,
            `6. चिकन को भूनना: एक पैन लें, उसमें 3-4 टेबल स्पून तेल डालें, सुनिश्चित करें कि चिकन डालने से पहले पैन का तापमान बहुत अधिक हो। इसे कुछ ही पलों में पलटें। ढककर पकने दें,
                                जाँच करते रहो। 6 मिनिट बाद चिकन भुन जायेगा. - अब चिकन को बाहर निकाल लें. बचे हुए तेल में से, ऊपर से पका हुआ हटा दीजिए और हम इस तेल का उपयोग करेंगे।`,
            `7. अंतिम खाना पकाने: पैन में बचा हुआ तेल डालें, 3 छोटी इलायची, दालचीनी तमाला, दालचीनी, हल्दी पाउडर, 2 चम्मच कश्मीरी लाल मिर्च पाउडर डालें, इसे जल्दी से भूनें और हमारा टमाटर का पेस्ट डालें,
                आवश्यक पानी डालें, 2-3 चम्मच टोमैटो कैचप डालें, अब 2 क्यूब मक्खन डालें, चिकन डालने के बाद, धीमी आंच पर पकाएं, 5 मिनट तक पकने दें`,
            `8. कसूरी मेथी को भून लें, कुचल दें, चिकन में डालें, 1 टेबल स्पून गरम मसाला डालें, ताजी क्रीम डालें (अब पकाना बंद कर दें), हो गया। ${fedbackTextHindi}`
        ]
        }
    },
    {
        id:4,
        key: 'SoyaAaloo',
        name: "Soya Aaloo",
        description: "Soya Aaloo with gravy, Jee lalchae, raha na jae",
        image: 'soyabin.jpg',
        ingredients:[
            { quantity:"50 g", code:"soya"}, 
            { quantity:"2", code:"potato"}, 
            {  quantity:"2 medium size", code:"onion"},
            {  quantity:"4 table spoon",code:"refined_oil"},
            {  quantity:"2 big spoon", code:"curd"},
            {  quantity:"2 tbsp", code:"ginger_garlic_p"},
            {  quantity:"as needed", code:"salt"},
            {  quantity:"2", code:"tomato"},
            {  quantity:"2",code:"cinnamomum_tamala"},
            {  quantity:"6-7", code:"black_pepper_p"},
            {  quantity:"1/2 tsp", code:"cumin_seeds"},
            {  quantity:"1/2 tsp", code:"turmeric_p"},
            { quantity:"1/2 tsp", code:"red_chilli_p"},
            {  quantity:"1/2 tsp", code:"cumin_seeds_p"},
            {  quantity:"1-2 tsp", code:"coriander_p"},
            { quantity:"1 tsp", code:"garam_masala_p"},
            {  quantity:"as needed", code:"coriander_leaves"},
        ],
        instructions:{
            english:[
            `1. Take 50g of soya chunks in a bowl, soak them in warm water for 5 minutes, and then squeeze out all the water.`,
            `2. Take 2 medium-sized potatoes, cut each into 4 pieces, and fry them in 2 tablespoons of oil in a cooker on medium flame for 3-4 minutes.`,
            `3. In the remaining oil in the cooker, add the soya chunks. Fry them for half a minute, and take them out when you see the soya turn brown in color.`,
            `4. Curry preparation: Add 2 tablespoons of oil in the same cooker. Add 2 cinnamomum tamala, 6-7 black pepper, and ½ teaspoon of jeera seeds. 
                Now add 2 chopped onions and cook them for 4 minutes over medium flame. Then add ginger-garlic paste and cook for 1 minute.`,
            `5. Now it's time to add powdered spices: add ½ teaspoon each of turmeric, red chili, cumin, coriander, and garam masala. Cook them over low flame for 2 minutes.`,
            `6. Add tomato paste or finely chopped 2 tomatoes to the cooker. Cook it covered for 4 minutes.`,
            `7. Curd (optional): Allow the spices to cool for a few minutes, then add 2 full spoons of curd. Cook for 3 minutes over medium flame. `,
            `8. Add the fried potatoes and soya chunks, and cook for 2 minutes over medium flame.`,
            `9. Add 2 cups of water (or adjust as per desired thickness), 1 tablespoon of salt (or adjust according to taste), and then cook covered for 2 whistles on medium flame. `,
            `10. Now garnish with coriander leaves, and it is ready to serve. ${fedbackTextEnglish}`
            ],
        hindi:[
            
            `1. एक कटोरे में ५० ग्राम सोया चंक्स लें, उन्हें गरम पानी में ५ मिनट के लिए भिगोकर, फिर सभी पानी को निचोड़ लें।`,
            `2. २ मध्यम आकार के आलू लें, हर आलू को ४ टुकड़ों में काटें, और कुकर में मध्यम आंच पर ३-४ मिनट के लिए २ बड़े चमचे तेल में तलें।`,
            `3. कुकर में बचे हुए तेल में, सोया चंक्स डालें। उन्हें आधे मिनट के लिए तलें, और जब आप सोया का भूरा रंग देखें तो उन्हें निकालें।`,
            `4. मसाले तैयारी: कुकर में २ बड़े चमचे तेल डालें। उसमें २ ताज पत्ता, ६-७ काली मिर्च, और १/२ छोटी चमच जीरा डालें। अब २ कटीले हुए प्याज डालें और मध्यम आंच पर ४ मिनट के लिए पकाएं। फिर अदरक-लहसुन का पेस्ट डालें और १ मिनट के लिए पकाएं।`,
            `5. अब मसाले डालने का समय है: १/२ छोटी चमच हल्दी, लाल मिर्च, जीरा, धनिया और गरम मसाला डालें। उन्हें हल्की आंच पर २ मिनट के लिए पकाएं।`,
            `6. कुकर में टमाटर का पेस्ट या ध्यान से कटे हुए २ टमाटर डालें। इसे ४ मिनट तक ढंक कर पकाएं।`,
            `7. दही (वैकल्पिक): मसालों को कुछ मिनटों के लिए ठंडा होने दें, फिर २ बड़े चमचे दही डालें। मध्यम आंच पर ३ मिनट के लिए पकाएं।`,
            `8. तले हुए आलू और सोया चंक्स डालें, और मध्यम आंच पर २ मिनट के लिए पकाएं।`,
            `9. २ कप पानी डालें (या वांछित मोटाई के अनुसार समायोजित करें), १ बड़ा चमच नमक (या स्वाद के अनुसार समायोजित करें), और फिर २ सीटी बंद करके मध्यम आंच पर २ सीटी दें।`,
            `10. अब धनिया पत्ती से सजाएं, और सर्व करने के लिए तैयार है। ${fedbackTextHindi}`
        ]
        }
    },
    

]

export default recipes