import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Element } from 'react-scroll'
import ImageGallery from 'react-image-gallery';

import gallery from "./Data"
import "react-image-gallery/styles/css/image-gallery.css";
import { ClickActionEvent } from '../../util/analytics'
 


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  cardHover: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "0 4px 8px 0 rgba(0,0,255,0.8), 0 6px 20px 0 rgba(0,0,255,0.8)",
    cursor: 'pointer',
    opacity:'0.7',
    transition: '0.2s linear'
    
  },
  cardMedia: {
    paddingTop: '22.25%',
    height: 70,
  },
  cardContent: {
    flexGrow: 1,
  },
}));


export default function Gallery() {
  
  const classes = useStyles();

  const [selectedItem, setSelectedItem] = React.useState('')

  const MouseOver = (t)  =>{
    setSelectedItem(t)
    console.log(selectedItem)
    
  }

  const MouseOut = (event)  =>{
    setSelectedItem('')
  }

  return (
    <React.Fragment>
 
      <Element id='gallery' name='gallery' style={{backgroundColor:"#050a30"}}>

        <div className={classes.heroContent} style={{backgroundColor:"#050a30"}}>
          <Container maxWidth="md" >
            <Typography component="h1" variant="h3" align="center" style={{color:"#7ec8e3"}} gutterBottom>
              My moments 🖼️
            </Typography>
            <Typography variant="h6" align="center" style={{color:"#7ec8e3"}} paragraph>
              These are few well spent, memorable moments I have lived...
            </Typography>
          </Container>

        </div>
        <Container className={classes.cardGrid} maxWidth="md">
        <ImageGallery onSlide ={(currentIndex)=>{ClickActionEvent("Gallery "+gallery[currentIndex]?.description, "gallery section", "gallery slide changed")}} items={gallery}   showIndex={false} showBullets={true} autoPlay={false} slideInterval={3000} slideDuration={450} showFullscreenButton={true} lazyLoad={true} showNav={true} showThumbnails={false}/>
        </Container>
      </Element>
 
    </React.Fragment>
  );
}
