import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer';
import AppMenuItem from './AppMenuItem'
import appMenuItems from './AppMenuItemsList'
import { Link } from 'react-scroll'
import { isRecipePage } from '../../util/common';
import { Link as MaterialLink } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HomeIcon from '@material-ui/icons/Home';




const useStyles = makeStyles({
    list: {
      width: 500,
    },
    fullList: {
      width: 'auto',
      backgroundColor:"#088E8B",
      color:"#090445"
    },
    appMenu: {
        width: '100%',
      }
  });

const AppMenu = (props) => {
  const classes = useStyles()
  const [navSelected, setNavSelected] = React.useState('');

    const list = () =>(
        <div
            //To decide width of Sidebar
            className={classes.fullList}
            //  className={classes.fullList, classes.list}

            role="presentation"  
            // onClick={props.toggleDrawer(false)}
            onKeyDown={props.toggleDrawer(false)}
            >

            <List component={Link} className={classes.appMenu} disablePadding>
            {appMenuItems.map((item, index) => (
                <AppMenuItem  setMenu={setNavSelected} {...item} menuSelected={navSelected} key={index} />
            ))}
            </List>
                
        </div>
    )
  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="left"
          open={props.drawerState}
          onClose={props.toggleDrawer(false)}
        >
          {isRecipePage() ? (
            <ListItem button>
              <MaterialLink
                color="inherit"
                className={classes.appBarButton}
                href="/"
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                Lets Explore Website
              </MaterialLink>
            </ListItem>
          ) : (
            list()
          )}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

export default AppMenu
