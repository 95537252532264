import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { ClickActionEvent } from '../../util/analytics'


const useStyles = makeStyles({
  root: {
    width: "80%",
    maxWidth: 800,
    paddingLeft: "10%",
  },

  desctop:{
    fontSize: "50%",
  },
  
  mobile:{
    fontSize: "23%" 
  },

  desctopArticle:{
    height: "50px",
  },
  
  mobileArticle:{
    height: "23px",
  }
});


const socialContactClickedEvent = (contactName) => {
  ClickActionEvent("Contact "+contactName, 'About me section', "About me social contact clicked")
}

export default function Info() {
  const classes = useStyles();
  var date1 = new Date("2/20/2018");
  var current = new Date();
  var date2 = new Date(
    current.getFullYear() +
      "/" +
      (current.getMonth() + 1) +
      "/" +
      current.getDate()
  );
  var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
  var diffYears = ((diffDays / 30 + 1) / 12).toFixed(1);

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h3" gutterBottom>
        About Me
      </Typography>

      <Typography variant="body1" style={{ fontSize: "20px" }} gutterBottom>
        Hi I am <span style={{ color: "#1fd7a3" }}>Javed Akhtar </span>, a
        writer who writes codes for computers as a Software Engineer, YouTuber,
        Bloggger, living in Siliguri, India. I am a Computer Science Engineer,
        currently working with awesome developers and I have {diffYears} years
        of experience. You can DM me on any of the social network or email📧 me
        at <span style={{ color: "#1fd7a3" }}>javedaktar73@gmail.com. </span>
        I love to work with geeks who are very excited on developing something
        with new tech stacks, lets meet and discuss something interesting <br />
        <span style={{ color: "#1fd7a3" }}>
          Chai☕ pe charcha karenge 😄...
        </span>
      </Typography>
      <Typography variant="h1" align="center" gutterBottom>
      <Link
          href="https://www.linkedin.com/in/javed-akhtar-07370613b/"
          target="_blank"
          onClick={() => socialContactClickedEvent('linkedin')}
        >
          <LinkedInIcon className={windowWidth>=650?classes.desctop:classes.mobile} style={{ color: "#0077B5"  }} />
        </Link>{" "}

        <Link
          href="https://www.youtube.com/@javedinfinite"
          target="_blank"
          onClick={() => socialContactClickedEvent('youtube')}
        >
          <YouTubeIcon className={windowWidth>=650?classes.desctop:classes.mobile} style={{ color: "#cd201f"  }} />
        </Link>{" "}
        <Link
          href="https://medium.com/@javedinfinite"
          target="_blank"
          onClick={() => socialContactClickedEvent('medium')}
        >
        {/* <img src="https://img.icons8.com/fluency/48/000000/medium-logo.png"/> */}
          <img className={windowWidth>=650?classes.desctopArticle:classes.mobileArticle}   src="https://img.icons8.com/ios-glyphs/30/000000/medium-monogram.png"/>
        </Link>{" "}
        <Link href="https://www.instagram.com/javedinfinite/" target="_blank" onClick={() => socialContactClickedEvent('instagram')}>
          <InstagramIcon className={windowWidth>=650?classes.desctop:classes.mobile} style={{ color: "#e4455f" }} />
        </Link>{" "}
        <Link
          href="https://www.facebook.com/people/Javed-Akhtar/100004054069579/"
          target="_blank"
          onClick={() => socialContactClickedEvent('facebook')}
        >
          <FacebookIcon className={windowWidth>=650?classes.desctop:classes.mobile} style={{ color: "#3b5999"}} />
        </Link>{" "}

        <Link href="https://twitter.com/javedinfinite" target="_blank" onClick={() => socialContactClickedEvent('twitter')}>
          <TwitterIcon className={windowWidth>=650?classes.desctop:classes.mobile}  style={{ color: "#55acee"}} />
        </Link>{" "}


      </Typography>
    </div>
  );
}
