import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Link as MaterialLink } from '@material-ui/core';
import { Link } from 'react-scroll'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AppMenu from '../side-bar/AppMenu'
import { ClickActionEvent } from '../../util/analytics'

//icons
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { isRecipePage } from '../../util/common';


const useStyles = makeStyles((theme) => ({
    showMenuButton: {
        [theme.breakpoints.between('sm','xl')]: { display: 'none' },
      },
  root: {
    flexGrow: 1,
    // backgroundColor: 'pink' , //#3f51b5, blue, pink

  },
  appBarSolid:{
    // height:"8%",
    backgroundColor: '#088E8B' ,
    color: "#090445",
    boxShadow: "10px 10px 8px #3aa5a0"

  },

  appBarTransparent:{
    
    color: "#2cbfaa", //#40b0bd  //#2cbfaa
    boxShadow: 'none',
    backgroundColor: 'rgba(67, 129, 168,0)',
  },
  appBarButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: { display: 'none'},
   fontSize: '1rem' 
    // color:'white'
  },
  righttoolbarButtons: {
    marginLeft: 'auto',
  },
  title: {
    flexGrow: 1,
  },
}));



const ButtonAppBar = () => {

  console.log()
  const classes = useStyles();

  const [leftMenu, setLeftMenu] = React.useState(false)
  const [navSelected, setNavSelected] = React.useState('');

  const menuClickedEvent = (menuName) => {
    ClickActionEvent("Menu " + menuName, "homepage", "app menu clicked")

  }

  const setNav = (t) =>{
    setNavSelected(t)
    menuClickedEvent(t)
  }



  const toggleDrawer = (menuState) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setLeftMenu(menuState);
  };

  
  //dropdown menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (t) => {
    setNavSelected(t)
    setAnchorEl(null);
  };

  const [navBackground, setNavBackground] = useState('appBarTransparent')
    const navRef = React.useRef()
    navRef.current = navBackground
    useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 310
            if (show) {
                setNavBackground('appBarSolid')
            } else {
                setNavBackground('appBarTransparent')
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

  const navList = [
    {name:'CoffeeWithMe', id:'contact'  },
        // {name:'Resume', id:'resume'  },
    {name:'Articles', id:'articles'  },
    {name:'Projects', id:'projects' },
    {name:'Skills', id:'skills'  },
    {name:'Youtube', id:'youtube'  },
    {name:'Gallery', id:'gallery'  },
    {name:'Chef-Corner', id:'food'  },
    {name:'BooksIRead', id:'books'  },

  ]

  return (
    <div>
      <AppBar className={classes[navRef.current]} position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            onClick={toggleDrawer(true)}
            className={classes.showMenuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={() => setNav("home")}
            style={{ color: navSelected == "home" ? "#f1f1f7" : "" }}
            edge="start"
            to="home"
            spy={true}
            smooth={true}
            duration={1000}
            component={Link}
            className={classes.appBarButton}
            color="inherit"
            aria-label="menu"
          >
            <HomeIcon style={{ fontSize: "1rem" }} />
          </IconButton>
          {isRecipePage()
            ? navList.map((item) => (
                <MaterialLink
                  color="inherit"
                  className={classes.appBarButton}
                  href="/"
                >
                  {item.name}
                </MaterialLink>
              ))
            : navList.map((item) => (
                <IconButton
                  onClick={() => setNav(item.name)}
                  style={{ color: navSelected == item.name ? "#f1f1f7" : "" }}
                  key={item.id}
                  edge="start"
                  to={item.id ? item.id : "home"}
                  spy={true}
                  smooth={true}
                  duration={1000}
                  component={Link}
                  className={classes.appBarButton}
                  color="inherit"
                  aria-label="menu"
                >
                  {item.name}
                </IconButton>
              ))}
          {isRecipePage() ? (
            ""
          ) : (
            <IconButton
              edge="start"
              className={classes.appBarButton}
              color="inherit"
              aria-label="menu"
              aria-controls="customized-menu"
              variant="contained"
              aria-haspopup="true"
              onClick={handleClick}
            >
              More <ArrowDropDownIcon />
            </IconButton>
          )}
          <Menu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            color="red"
          >
            <MenuItem
              onClick={() => {
                handleClose("My Fav Quotes");
                menuClickedEvent("My Fav Quotes");
              }}
              style={{ color: navSelected == "My Fav Quotes" ? "#7EC8E3" : "" }}
              component={Link}
              to="quotes"
            >
              My Fav Quotes
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose("My Thoughts");
                menuClickedEvent("My Thoughts");
              }}
              style={{ color: navSelected == "My Thoughts" ? "#7EC8E3" : "" }}
              component={Link}
              to="thoughts"
            >
              My Thoughts
            </MenuItem>
            {/* <MenuItem onClick={()=>{handleClose('recipes'); menuClickedEvent('recipes')}} style={{color:navSelected=='recipes'?'#7EC8E3':''}} component={Link} to="recipes" >Recipes</MenuItem> */}
          </Menu>

          {/* After below div all components will be aligned right due to flex 1 from class=title */}
          {/* <div edge="start" className={classes.title} color="inherit" aria-label="menu">
          </div> */}

          <div className={classes.righttoolbarButtons}>
            {/* <IconButton color="inherit"><ArrowDropDownIcon /></IconButton> */}
          </div>
        </Toolbar>
      </AppBar>
      <AppMenu toggleDrawer={toggleDrawer} drawerState={leftMenu} />
    </div>
  );
}

export default  ButtonAppBar
