const articles = [
    {
        id:1,
        name: "CORS",
        description: "An article which explains CORS concept and helps to avoid any CORS issue during development",
        deployedLink: "https://medium.com/@javedaktar73/all-about-cors-cross-origin-resource-sharing-b861d63cf29a",
        image:"cors.PNG"
    },
    {
        id:2,
        name: "Torrent",
        description: "An article which explains torrent protocol and how it helps to establish peer-to-peer network",
        deployedLink: "https://medium.com/@javedaktar73/all-about-torrent-world-804d2c5172c0",
        image:"torrent.PNG"
    }, 
    {
        id:3,
        name: "JWT",
        description: "An article which explains Jason Web Token(JWT) in detail by explaining it's therory with practical code using Node an React",
        deployedLink: "https://medium.com/@javedinfinite/all-about-jwt-part-1-basics-d9672943bab1",
        image:"jwt.png"
    }, 
]

export default articles