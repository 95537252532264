const gallery = [
    {
        id:1,
        name: "Darjeeling",
        description: "Darjeeling trip",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLXcBoo4U52ifHhCg_dm1nGkv6U_0Jn26y65c-xuYwAeOtcPG8axpVfgsSrQSKCZpxYLYYgYmMojVNQNhAbegLvf4B8ODc9AWY42cbXlaVxihi9K8by_wVLU8agTKqZhvktjWtvAmEmta60YqsWP-BrT=w1185-h889-no',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:1.1,
        name: "",
        description: "Mahaka Temple-Darjeeling-1",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLWg0WhWvt7b4YbUYp2s9J5zluuREWKQAWdaWBCgPUJUzq-D6F0JQ4OsUbdZXXbHBxnrSUx0gvSQagMH3OsrWSNW3TcUyl3VF3l_PxhT0faJgn7-1NDvElZbOdJo-EqpfHtrG8oYxvNgoJjsocVhGk63=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:1.2,
        name: "",
        description: "Darjeeling-2",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLWfskO3JRoI_FXPmTjaUQLUrTuYnj4KCf09KjunaTl0Z7QWmHRdfrIRtWd6MQZ8B0RSAPuexr0wc8HMJ8VcM7SYzxijVzjLcTu4opbcsTSgcB59Y_tGRAs-a99nO1anaydPdhQKdSP3h_t_rvI-ZIWV=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },

    {
        id:2,
        name: "",
        description: "Hail stones in siliguri",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLX0SvH45Gu2_5xVL2FDLvc6KXKYvcDvQMWsmuPXM2h3lp0WPREgM-rBkE1hlf_Gr1Zd0M5sjvFE1tc3r3VVVpswSKCrZHlHTIWZ-jKEUjSMaYbhKg1suIaEiTvqtpPAMhtTvLcNcvObapUXhDHg4mxo=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:3,
        name: "",
        description: "My Kitty",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLVqZqM3P8LhMbtUn_hHvA3EppxePQrl5eLXZQzg7Lyf8YEMaSpqQPlq_qUX0NxZoceUqA-__bh_JuSSn9obKar60ajsitZc_vDAM1_rruZiZ28EROdrGVI5CJa4Uoknj4f6MhJfiFGmAa6S4qwZhw4z=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:4,
        name: "",
        description: "Fafri Forest",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLWwdNNqF6xBWMpxdeJoDjloxtKbBdxgb8ZzFMegJu8Awt9nE6NEt9jWjDwCv1ehq63iUiL0r0J5xb0uZqAmlpHx2Cr7UZy-rTKET8LhOxeXYrujs017DZy0faMx36utuR7fVvNJD1ip9A32YmWHKno9=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:4.1,
        name: "",
        description: "Fafri Forest - 1",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLVIxyG6dOaxCAmG4WGSwncTWGJhj3sL2WqftABZuYUJR-oeIUIUCkFDAjSbFOfgciM92Sc_ZmFpMDWiUcexAK-eJnzkJ1EQnLXlchPwUiB3qdLB8h6Fu6_FmavF-wi_gvjdnbMjQsIUfOACqMOJGet6=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:5,
        name: "",
        description: "Teesta Low Dam",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLWDzW1FJ4caAFgn_YYrFra4cVTz3EdklHy0kLgsNr48RrfhrnSkIibN5mrfkv2ca2nVcjwt5j85JYfWJlR_NBTUzwGG3K1NbjWgAmtSAH-6Zq3YWHh3jZ1IrBXUGxaY8NBWHdnRDF31G42UqpC48wOz=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:5.1,
        name: "",
        description: "Triveni Camping",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLVNYpAESqZPFAkikbEHfBMo4jOkZnrQkqjFOkpoX2lvzsCN5XCM1GWxqlzSVrolp_i9J-MNz8c-0LjtBx7P5AwvvffxxvjrFkQpP22mMpwXMwDkvQSOihWpjRlJXd70tkMnFazrDmAyKyl2ctmoHx4B=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:6,
        name: "",
        description: "Triveni Camping-1",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLUR8F0C2I_RnFn-w5c2D2JCknWbG-DFMHUJCbKUfQVLsLyvwa3zeG1gEV73OgIUK_M9e1nWhEbKvCJL7gu0ar97gakd_22Z5H-ilQDIqO1JV9txdsYBgduQeuGedftzblMbDQPltDD7flA_Qb5h11G3=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:7,
        name: "",
        description: "Siliguri view from Kurseong",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLUx5vNWffGF_jRErZOYoDSysfeKmyNcvgh9Nf8B_tSx84KXVe1fVjxtvNPahOy3pTaoUcMDwNSnTiq-OmMt85mdHaCeTmfzabYOf85UgNyBtYIS3c1S0qd63uj4nG-XVVxt0q-Jki4lo2rbbLvIaY40=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:8,
        name: "",
        description: "Mirik Lake",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLXKvUZgMhT8Ea0QckmWKR-_fNzjR-ZRjThAbjhROGV2wSfwNWfcRwtj2_I54xK3MxyrKzoFsjefz76FGNJO-AO8glHBkmU3l20g3wRBA7T5IZar0Objs_9KwXhgsOoN5e9-84P8n7j9V_FswdOCgrYr=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:10,
        name: "",
        description: "Bindu Dam",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLU34NB3q_ifmr3fVAr9z12tiGAE6DRMxtI6DS4A1zKhEgSPCQG-WPs1qfksTQQsVbau3ZktAeYrwkbwgpPCX_qyHUaUXyj76SI_KMEst-qmEZgcN6wZzzQ2egvUVCkcVZEbDXA6LuvmrN207X5Nrsqd=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:11,
        name: "",
        description: "Tea garden - Sukna",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLXvrdGsLEyYYWlGkOBwMDE8p1KiZAjBH9gJiwaQzNuTw0I83bT9Vt5i76u_Ju3fHs9zfB3sUOl3nZMP19xergKQwBImwGEhB1PTp1_5gstYn_EgmrtkJfJ5zGFCyHYGXPJMW3a4x8plLYa1IQOsxh-t=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:12,
        name: "Pigeon",
        description: "",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLVXaUDQ4sgG9V9sPDKYkYh0zDAgHtiIW2SzGG7iEyDLtflNNj7QVTd8_mi6lmop7RZe7Ib0epLXjC6hlrvoSzlYNbpClE8EuY21oySE4WbZhMr-jAXVU_Ik3ptdkIGuToksZYa9wQyUneg94kQtbWLe=w678-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
        {
        id:13,
        name: "Pigeon",
        description: "Bengaluru Lockdown-1",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLUcy-kRforLOGVwywuoQsdnGuul_LDLOe_rNCuCDHgyWhzulb7TOyLJuEozuDi1UqMpXfyoTJx4cd6OZIoWur_9b8zgOTlaWHh4UrbJDc-raLPaUM0HWqYVCbn44hUDcfMETmSgH6n5X0iR_8Vr9mwb=w678-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:14,
        name: "Pigeon",
        description: "Bengaluru Lockdown-1.1",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLWMCXTr3mMg-WG19TVpviA6HWjlAOneU9CRWzfCojTCGUBcOzj0DO86HLOV3KF6-JiznGLGscfpE7C5PROl1S-5hJ14qAt6Har5r7XWkbfuYTLHviDoBZvPEUAdx-2Zv4xGSBctmRiJgm5r1xwOCs55=w509-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:15,
        name: "Pigeon",
        description: "Bengaluru Lockdown-1.2",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLVx4Xs-2SYxcdJ5ARuo-tEBkmrUrP1hQmDsXQ1SG7bkw50nEjuUxkAL9NfuKj6H5LVIMJc8EwDHMP3aBm-fTwXPfIWVHrs40hX8fClzQVGg0n3k2zMMAt47BOIh8pWIDri65LeZEqwHBsRruXu0sBFy=w509-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:16,
        name: "Sanai",
        description: "Ghar wali Sanai ka phool",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLWUn_YQWberdb6jb0xt5FJJeXX8NYSTpObXXLBYvLxumrMJqd-KSzs3XHfcbPx8bXUCq5qXCOfkbbjrym2gwWiEMZ08Ug8LEBun8puYomGt6zUfsw9A6NwOm5pOqAGsZsjrCjdUzcpp-Na_At_w_Hjs=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:17,
        name: " ",
        description: "Isckon Temple",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLXvijmjROQYJH_P-QwZE4Hfz1FCXFqVnVjt1UNLrUA0OFPsf3HlxgHcirZfH6EvNZgKwab8K1oe1C2M8VNljC7kh6JOAvLjq2oU3EhuQs9iOwibPf5Qx6bueRE0FJ1vJ34udArCspY6kN-5OQTX7Uwx=w1219-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:18,
        name: " ",
        description: "Moment in the air before sunrise",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLWI_UO1GdMyyJgiXEDEn52TDphYoxcDd8EICdGpgkbjpHaEDhjFRoTKqWvo6LtsRCujURYiZRloz-3EQvAglBMQzyGvvy0WtXjKKnyMpOKO1rgKU9yd6DI0mZTcj4W6473HlRAtGMLGYf6QuB0Qwfg1=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:19,
        name: " ",
        description: "Gailam Hanging bridge",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLVmkFGka8eAkhglocpz-K5LaaSLyCPHwgZnc34KNuY1O7xeTNLnQSQ_ioZoaxXhhaeMOnLqU2KlvW4DMZZ4gPWhxmAhof0kEa83RQYrxJBq7Y5jQ0MSWyKmrHFEn6btYd4i7cbTSZbnveNHfvNIWvMJ=w678-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:20,
        name: " ",
        description: "Ooty Trip",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLWnOdzIaAY0sZFIdKGfzJxToOimlkchTH58ZAeqycAxvpvuOGGXy_jUbqx5Jcrh4-fIj1zN0iDrAhbhBViZTm9zraA9y5kVTiirE58yYeDbsBKuS-yT-tyJtDecM-ms9iszpFQpeTl4pX2HO_sYUpWR=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:21,
        name: " ",
        description: "Ooty Trip - 1",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLXyeiudDyXd3nLWU9lqtPSeI70927UqRtu1wMiUF-4AzioCw1Q0N8-_hXhLuF8TRYXkyFh_22ifrbrzait-TTssSYU1BWzY5p0kN0TAbGTH0HRWBoJSRzBT0u9_QG1Y6_zIRGvAtBEGedPvv5FudzNu=w678-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:22,
        name: " ",
        description: "Ooty Trip - 2",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLUpdkEtF_iikSnDEUM53hdy0KK3rUBfJxoun6wXT5fa1WGoM2rUQ2FItU0JMfykEtV0weh0uaedGdheBrnefWzI6704spqCKZ_6DmGQnxrv6wxMD3Ls6UJBTRpAmdwcSDmhQwMkY8oPHxQWFVG--5Bh=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:23,
        name: " ",
        description: "Ooty Trip - 3",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLW2OY0JywSmx-dQk7A-8_HzHwDoMkDEECaJiOaOTIR-Oihc6oQslGiNHGZ_hgOv06Q6pjbKYsMB_ny_QaQXgNbm-sqDwXFwVx72aD1BMVmXI0beMHpMeSUmwkoAtARf1RgEPKO8feoKcCE7KRvm5bDb=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:24,
        name: " ",
        description: "Karnataka Legislature",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLXoq8ja9g_7U_52veMKnOsfTmwh7P9imdGmbpjOxS8DEfYPoap5KiedhZD49JX5nLDYtVgAcQ_axvUZ8IDhnGNuo4x_hA7uAbl_eO5Lfrv5dxW1dc2ehvlthXMxzYQazloMDVoBoGW5Y8ukKOhz4PWG=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:25,
        name: " ",
        description: "Mumbai - 1",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLVETqRSTVy4UbGFCDaQ1Xgi2DwfsHTt8D1edBowfc6Qeczi-xEcko5C_kRA0oUZkVkowZQWHqQNdP5FpvwjMkfSpSja9LjCw5bvxQk9ZY93Od1A9L4LzS4YsMfuZXmB5KKDpoEIKwq98dYG6lttrg1q=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:26,
        name: " ",
        description: "Elephanta Island",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLUdBN2H7krnIYMfZy6Ed0TdnpxzIMqZLtArq4BGz08wnsjw20TgpoakCc92YjeODDEF30FTP6pBGvT0VBfRM-JjUVb2vaUL0ykoVhf9o_CeoYoNIflvyfLl45XCwK7WJWxu7Z5QvbMn96PCZpdZRzYX=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        id:26,
        name: " ",
        description: "Holi party",
        original: 'https://lh3.googleusercontent.com/pw/AM-JKLX7iXRaul4Zw2mxIK3t_qov77B1TyQsjIqDuBLbQbn5nDuOgINELUDX6A8Bu7DAjb2PWDV6oLKEI0HMY-PMRqA5C4rzHAYUJIczeAgZ1352wucL0Rgvr6yNo1VGz6Z1lossTbIuf9R2N2g3j-KgXssF=w1204-h903-no?authuser=0',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },




]

export default gallery