import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    width:"100%",
    backgroundColor: "#0c6170",
  },
  media: {
    height: 60,
    width:60,
    borderRadius: 50,
  },
});

export default function OneThought(prop) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea >
        <CardContent>
          <Typography  style={{color:"#dbf5f0", fontSize:"20px", fontFamily:"ui-monospace"}} variant="body2" color="textSecondary" component="p">
          “ {prop.item.quote} ”
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
