import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Element } from 'react-scroll';
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { ClickActionEvent } from '../../util/analytics'

import projects from "./Data"
 


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  cardHover: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "0 4px 8px 0 rgba(0,0,255,0.8), 0 6px 20px 0 rgba(0,0,255,0.8)",
    cursor: 'pointer',
    opacity:'0.7',
    transition: '0.2s linear'
    
  },
  cardMedia: {
    paddingTop: '22.25%', // 16:9,
    height: 70,
 
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));


export default function Projects() {
  
  const classes = useStyles();

  const [selectedProject, setSelectedProject] = React.useState('')

  const MouseOver = (t)  =>{
    setSelectedProject(t)
    console.log(selectedProject)
    
  }

  const MouseOut = (event)  =>{
    setSelectedProject('')
  }

  return (
    <React.Fragment>
 
      <Element id='projects' name='projects' style={{backgroundColor:"#033736"}}>

        <div className={classes.heroContent} style={{backgroundColor:"#033736"}}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h3" align="center" color="textPrimary" style={{color:"#37beb0"}} gutterBottom>
              POCs in React-Node 💻
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" style={{color:"#37beb0"}} paragraph>
              These are some POCs done using react, node, postgres, sqllite, firebase, indexDb, material/semantic ui.
              To know about professional projects I have worked on, please refer to my linkedIn profile.
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="lg" >
          <Grid container spacing={4}>
            {projects.map((project) => (
              <Grid item key={project.id} xs={12} sm={6} md={4}>
               <Link href={project.deployedLink} target="_blank" onClick={()=>{ClickActionEvent('Project '+project.name, 'projects section', 'project clicked')}}> 
                <Card style={{backgroundColor:"#0abab5"}}  className={project.name==selectedProject?classes.cardHover:classes.card}  onMouseOver={()=>MouseOver(project.name)} onMouseOut={MouseOut}>
                  
                  <CardMedia
                    className={classes.cardMedia}
                    image={process.env.PUBLIC_URL + project.image}  
                    title={project.name}
                  />
                  
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {project.name} <TouchAppIcon/>
                    </Typography>
                    <Typography>
                      {project.description}
                    </Typography>
                  </CardContent>
                </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Element>
    </React.Fragment>
  );
}
