import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '0px  ',
    padding: "7px",
    backgroundColor: theme.palette.background.paper,
    borderRadius:"6%"
  },
  copiedSucess: {
    border: '0px  ',
    padding: "7px",
    backgroundColor: theme.palette.background.paper,
    borderRadius:"6%",
    color:"green"
  }
}));

export default function TransitionsPopper(prop) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [copied, setCopied] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCopied(false)
  };

  const copyClick = () =>{
    navigator.clipboard.writeText(window.location.origin+"/#/share/"+(parseInt(prop.id)-1))
    setCopied(true)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  return (
    <div>
      <span aria-describedby={id} style={{fontSize:"1rem", color:"#1a245b"}}  onClick={handleClick}>
      <span style={{ marginBottom:"10px"}}>Share</span> <ShareIcon  style={{marginBottom: "-5px"}} />    
      </span>
      <Popper id={id} open={open} anchorEl={anchorEl} style={{marginRight:"30px"}} transition placement="left-start">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={copied?classes.copiedSucess:classes.paper}>{copied?"link copied":"Copy: "+window.location.origin+"#/share/"+(parseInt(prop.id)-1)}
                <IconButton >
                    {copied?<img alt="test" onClick={() =>   setAnchorEl(null)} src="https://img.icons8.com/color/16/000000/checked.png"/>:<img alt="test" onClick={() =>   copyClick()} src="https://img.icons8.com/material-outlined/24/000000/copy.png"/>}
                </IconButton> 
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
