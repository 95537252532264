import React from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Element } from 'react-scroll'
import ImageGallery from 'react-image-gallery';
import { ClickActionEvent } from '../../util/analytics'


import Books from "./Data"
 


const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
 
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    
  },
 
 

}));


export default function BooksIRead() {
  
  const classes = useStyles();

  return (
    <React.Fragment>
 
      <Element id='books' name='books' style={{backgroundColor:"#0c2d48"}}>

        <div className={classes.heroContent} style={{backgroundColor:"#0c2d48"}}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h3" align="center" style={{color:"#c1eeec"}} gutterBottom>
              Books I Read 📚
            </Typography>
            <Typography variant="h6" align="center" style={{color:"#c1eeec"}}  paragraph>
              These are some of the books I have read which helped me a lot to become a better version of me.
              Some of them are recommended by my colleagues and some are by people I follow as my mentor.
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid}     maxWidth="md">
         
            <ImageGallery items={Books} onSlide ={(currentIndex)=>{ClickActionEvent("Books " + Books[currentIndex]?.originalTitle, "books section", "book slide changed")}}    showIndex={false} showBullets={true} autoPlay={false} slideInterval={3000} slideDuration={450} showFullscreenButton={true} lazyLoad={true} showNav={true} showThumbnails={false}/>
          
        </Container>
      </Element>
 
    </React.Fragment>
  );
}
