import React from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Element } from 'react-scroll'
import recipes from "./Data"
import "react-image-gallery/styles/css/image-gallery.css";
import SingleRecipe from "./SingleRecipe"
import RecipeModal from "./RecipeModal"
import { ClickActionEvent } from '../../util/analytics'
import { Link as MaterialLink } from '@material-ui/core';
 


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  cardHover: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "0 4px 8px 0 rgba(0,0,255,0.8), 0 6px 20px 0 rgba(0,0,255,0.8)",
    cursor: 'pointer',
    opacity:'0.7',
    transition: '0.2s linear'
    
  },
  cardMedia: {
    paddingTop: '22.25%',
    height: 70,
  },
  cardContent: {
    flexGrow: 1,
  },
}));


export default function ChefCorner() {
  

  const classes = useStyles();

  const [selectedItem, setSelectedItem] = React.useState('')
  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState({name:"", ingredients:[], instructions:{english:[], hindi:[]}});

  const MouseOver = (t)  =>{
    setSelectedItem(t)
    console.log(selectedItem)
    
  }

  const MouseOut = (event)  =>{
    setSelectedItem('')
  }



  const handleClickOpen = (item)  => {
    ClickActionEvent("Food " + item.name, 'Food section', 'Food item clicked')
    setCurrentItem({name:item.name, ingredients:item.ingredients, instructions:item.instructions})
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

  return (
    <React.Fragment>
 
      <Element id='food' name='Chef-Corner' style={{backgroundColor:"#dbf5f0"}}>

        <div className={classes.heroContent} style={{backgroundColor:"#dbf5f0"}}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
              Chef Corner 👨‍🍳
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              Recipes which I have learned to cook while living away from home...
              This may help you to cook in very less time with proper instructions without spending much time on youtube to note and rewatch.
              If you have cooked something before, you don't need youtube, just instructions in 2 minutes and you can save your time. <b/>
              <MaterialLink
                  color="blue"
                  className={classes.appBarButton}
                  href="#/recipes"
                >
                  Click me to check out all the recipes
                </MaterialLink>
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          
          <Grid container spacing={4}>
            <RecipeModal modalStatus={open}  handleClose={handleClose} name={currentItem.name} ingredients={currentItem.ingredients} instructions={currentItem.instructions}/>
            {recipes.map((item) => (
              <>
              {['matarPaneer', 'rajma', 'butterChicken'].includes(item.key) && <Grid item key={item.id} xs={12} sm={6} md={4}>
                <Card  onClick={()=>handleClickOpen(item)} className={item.name==selectedItem?classes.cardHover:classes.card}  onMouseOver={()=>MouseOver(item.name)} onMouseOut={MouseOut}>
                  <SingleRecipe  name={item.name} id={item.id} noIngredients={item.ingredients.length} image={item.image} description={item.description}/>
                </Card>
                 
              </Grid>}
              </>

            
            ))}
          </Grid>
          <MaterialLink
                  color="inherit"
                  className={classes.appBarButton}
                  href="#/recipes"
                >
                  Click me to check out all the recipes
                </MaterialLink>
        </Container>
      </Element>
 
    </React.Fragment>
  );
}
