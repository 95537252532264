import React from 'react'
// import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
// import { SvgIconProps } from '@material-ui/core/SvgIcon'

import List from '@material-ui/core/List'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'

import AppMenuItemComponent from './AppMenuItemComponent'
import { ClickActionEvent } from '../../util/analytics'
 

const AppMenuItem  = props => {
  const { name, link, Icon, items = [], menuSelected, setMenu } = props
  const classes = useStyles()
  const isExpandable = items && items.length > 0
  const [open, setOpen] = React.useState(false)

  const menuClickedEvent = (menuName) => {
    ClickActionEvent("Menu " +menuName, "homepage mobile", "app menu clicked")
  }

  function handleClick(link) {
    setOpen(!open)

  }

  const MenuItemRoot = (
    <AppMenuItemComponent className={classes.menuItem} link={link} onClick={handleClick}>
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon onClick={()=>{setMenu(link); menuClickedEvent(link)}}  className={classes.menuItemIcon}>
          <Icon  style={{color:menuSelected==link?"#f1f1f7":""}}/>
        </ListItemIcon>
      )}
      <ListItemText onClick={()=>{setMenu(link); if(link)menuClickedEvent(link)}} primary={name} inset={!Icon} />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </AppMenuItemComponent>
  )

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <AppMenuItem setMenu={setMenu} {...item} menuSelected={menuSelected} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    menuItem: {
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
        '& .MuiListItemIcon-root': {
          color: '#fff',
        },
      },
    },
    menuItemIcon: {
      color: '#090445',
    },
  }),
)

export default AppMenuItem
