import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typed from "react-typed";
import { makeStyles } from "@material-ui/core/styles";
import avatar from "./avatar.jpg";
import ParticlesBg from "particles-bg";
import Divider from '@material-ui/core/Divider';

import mirik from "./mirik.jpg";

import Projects from "../Projects";
import Articles from "../Articles"
import Youtube from "../Youtube";
import Skills from "../Skills";
import Gallery from "../Gallery"
import Contact from "../Contact"
import ChefCorner from "../ChefCorner"
import BooksIRead from "../BooksIRead"
import FavQuotes from "../FavQuotes"
import MyThoughts from "../MyThoughts"


const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: theme.spacing(1),
  },
  title: {
    color: "white",
  },
  subtitle: {
    color: "white",
    textTransform: "uppercase",
  },
  typedContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100vw",
    textAlign: "center",
    zIndex: 1,
  },
  typedContainerMobile: {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100vw",
    textAlign: "center",
    zIndex: 1,
  },
}));

const myImage = {
  backgroundImage: "url(" + mirik + ")",
  backgroundColor: "#346794",
  backgroundPosition: "center",
  backgroundSize: "100% 100%",
  backgroundRepeat: "no-repeat",
  height: "80vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  clipPath: "polygon(0 0, 100% 0, 100% 80%, 0 100%) ",
  backgroundBlendMode: "multiply",
};
const myImageMobile = {
  backgroundImage: "url(" + mirik + ")",
  backgroundColor: "#346794",
  backgroundPosition: "center",
  backgroundSize: "100% 50%",
  backgroundRepeat: "no-repeat",
  height: "40vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  clipPath: "polygon(0 0, 100% 0, 100% 80%, 0 100%) ",
  backgroundBlendMode: "multiply",
};

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const classes = useStyles();
  return (
    <div>
      <div id="home" style={windowWidth >= 650 ? myImage : myImageMobile}>
        <div>
          <Box
            className={
              windowWidth >= 650
                ? classes.typedContainer
                : classes.typedContainerMobile
            }
          >
            <Grid container justify="center">
              <Avatar
                className={classes.avatar}
                src={avatar}
                alt="Javed Akhtar"
              />
            </Grid>
            <Typography className={classes.title} variant="h5">
              <Typed strings={["Javed Akhtar ∞"]} typeSpeed={40} />
            </Typography>

            <Typography className={classes.subtitle} variant="h7">
              <Typed
                strings={[
                  "Frontend Developer - React",
                  "Backend Developer - Node",
                  "Full Stack Developer",
                  "AWS Experienced"
                ]}
                typeSpeed={40}
                backSpeed={50}
                loop
              />
            </Typography>
          </Box>
        </div>
        <ParticlesBg type="cobweb" bg={true} />
      </div>
      <Contact/>
      <Divider/>

      <Articles />
      <Divider/>

      <Projects />
      <Divider/>

      <Skills/>
      <Divider/>

      <Youtube/>
      <Divider/>

      <Gallery/>
      <Divider/>

      <ChefCorner/>
      <Divider/>

      <BooksIRead/>
      <Divider/>
      
      <FavQuotes/>
      <Divider/>
      
      <MyThoughts/>

    </div>
  );
};
export default Home;
