import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { ClickActionEvent } from '../util/analytics'


function Copyright() {
  return (
    <Typography variant="body2" color='textSecondary'  align="center">
      {'NoCopyright © '}
      <Link color="inherit" href="javedinfinite.com">
      javedinfinite.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#2c3f46',
    // backgroundColor: '#424242',
    color: 'white'
    // padding: theme.spacing(6, 0),
  },
}));

export default function Footer(props) {
  const classes = useStyles();
  const { title } = props;

  const socialContactClickedEvent = (contactName) => {
    ClickActionEvent("FooterContact " + contactName + "link footer", "Footer section", "Social contact clicked from footer")
  }

  return (
    <footer className={classes.footer}>
      <Container maxWidth="xl"  >
        <Typography variant="h6" align="center" gutterBottom>
          {title}  
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
        <Link   href="https://www.instagram.com/javedinfinite/" target="_blank" onClick={() => socialContactClickedEvent('instagram')}>
           <InstagramIcon style={{ color: '#e4455f',   }}  />
        </Link>{' '}
        <Link   href="https://www.facebook.com/people/Javed-Akhtar/100004054069579/" target="_blank" onClick={() => socialContactClickedEvent('facebook')}>
           <FacebookIcon style={{ color: '#3b5999',   }}  />
        </Link>{' '}
        <Link   href="https://www.linkedin.com/in/javed-akhtar-07370613b/" target="_blank" onClick={() => socialContactClickedEvent('linkedin')}>
           <LinkedInIcon style={{ color: '#0077B5',   }}  />
        </Link>{' '}
        <Link   href="https://twitter.com/javedinfinite" target="_blank" onClick={() => socialContactClickedEvent('twitter')}>
           <TwitterIcon style={{ color: '#55acee',  }}  />
        </Link>{' '}
        <Link   href="https://www.youtube.com/@javedinfinite" target="_blank" onClick={() => socialContactClickedEvent('youtube')}>
           <YouTubeIcon style={{ color: '#cd201f',   }}  />
        </Link>{' '}
        <Link
          href="https://medium.com/@javedinfinite"
          target="_blank"
          onClick={() => socialContactClickedEvent('medium')}
        >
        {/* <img src="https://img.icons8.com/fluency/48/000000/medium-logo.png"/> */}
        <img src="https://img.icons8.com/ios-glyphs/30/000000/medium-monogram.png"/>
        </Link> 
        </Typography>
 
        <Copyright />
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
