import ReactGA from "react-ga4";
import { isLocalDevelopment } from "../constants/environment";

export const ClickActionEvent = (action, category, label) => {
  if(!isLocalDevelopment()){
    ReactGA.event({
      category: category,
      action: action+" clicked",
      label: label,
    });
  }
  }