const thoughtList= [

    {
        id:1, name:"Javed Akhtar Ansari",
        desc: `You don't get to know`, 
        quote:`You don't get to know when you have become something who is getting wasted for all the transactions between time and money.

        And when you get to know, you seek nature to understand your value. 
        
        But again the cycle repeats itself and you ask yourself : There is no value in your philosophical words/thoughts if you have not done something really worthy. And after this conversation to yourself you return back to the same place where you started...
        
        This keeps repeating... 
        I am human - Javed (7 july 2021)
        `, 
        image:"https://media-exp1.licdn.com/dms/image/C5103AQH9FZ7t5e_Ckw/profile-displayphoto-shrink_800_800/0/1542788077159?e=1638403200&v=beta&t=8SHYXccReLaaSDlpCTiHSB1jgR4LqBtlemdAQNOO_oU"
    },
    
    {
        id:1, name:"Javed Akhtar Ansari", 
        desc: `The truth is people read kabir`, 
        quote:` The truth is people read "Kabir Ke Dohe" just for the sake of reading, not for converting them into action of their own life.
                e.g. If they start implementing them in real life, religion system will get obsolete`, 
        image:"https://media-exp1.licdn.com/dms/image/C5103AQH9FZ7t5e_Ckw/profile-displayphoto-shrink_800_800/0/1542788077159?e=1638403200&v=beta&t=8SHYXccReLaaSDlpCTiHSB1jgR4LqBtlemdAQNOO_oU"
    },

    {
        id:1, name:"Javed Akhtar Ansari", 
        desc: `Sometimes you feel that people around you`, 
        quote:` Sometimes you feel that people around you are somehow not allowing you to be the change you want to be...they will laugh upon your change... They are not ready to accept that you can also be this or that........
                In that case simply ignore them if you believe that the change is good for you and for your surrounding...Be You...Believe in you🤟`, 
        image:"https://media-exp1.licdn.com/dms/image/C5103AQH9FZ7t5e_Ckw/profile-displayphoto-shrink_800_800/0/1542788077159?e=1638403200&v=beta&t=8SHYXccReLaaSDlpCTiHSB1jgR4LqBtlemdAQNOO_oU"
    },

]

export default thoughtList
