import React from 'react';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Element } from 'react-scroll'
import {ClickActionEvent } from '../../util/analytics'

import skills from "./Data"
import CustomizedProgressBars from "./Template"
 


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },

  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  cardHover: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "0 4px 8px 0 rgba(0,0,255,0.8), 0 6px 20px 0 rgba(0,0,255,0.8)",
    cursor: 'pointer',
    opacity:'0.7',
    transition: '0.2s linear'
    
  },

}));


export default function Skills() {
  
  const classes = useStyles();

  const [selectedItem, setSelectedItem] = React.useState('')

  const MouseOver = (t)  =>{
    setSelectedItem(t)
    console.log(selectedItem)
    
  }

  const MouseOut = (event)  =>{
    setSelectedItem('')
  }

  return (
    <React.Fragment>
 
      <Element id='skills' name='skills'>

        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
              Skills 🥇
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              The beautiful thing about learning  is that no one can take it away from you - APJ Abdul Kalam ...
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="xl">
          <Grid style={{fontSize:"10px"}} container spacing={5} >
            {skills.map((item) => (
              <Grid item key={item.id}         onMouseOver={()=>MouseOver(item.name)} onMouseOut={MouseOut}
              className={item.name==selectedItem?classes.cardHover:classes.card} >
               <Link href={item.deployedLink} target="_blank" onClick={()=>{ClickActionEvent('Skill '+item.name, 'skills section', 'skill clicked')}}> 
                    <CustomizedProgressBars name={item.name} points={item.points} icon={CameraIcon} image={item.image}/>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Element>
 
    </React.Fragment>
  );
}
