import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import OneInstruction from "./OneInstruction"
import OneIngredient from "./OneIngredient"
import IngredientsList from "./IngredientsList"


 
export default function RecipeModal(prop) {


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (prop.modalStatus) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [prop.modalStatus]);

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [hindi, setHindi] = React.useState(false);
  const handleChange = (event) => {
    setHindi(event.target.checked)
  };
  let instructionsList  = []
  if(hindi)
    instructionsList= prop.instructions.hindi
  else
    instructionsList= prop.instructions.english
  return (
    <div style={{margin:"0", padding:"0px",}}>
        
            <Dialog
            maxWidth="md"
            fullScreen={windowWidth >= 650?false:true}
            open={prop.modalStatus}
            onClose={prop.handleClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
      
   

           <FormControlLabel style={{color:"green"}}
               control={      <Switch checked={hindi} onChange={handleChange} name="Hindi"  />}
               label="Read Instructions in Hindi"/>
        <DialogTitle id="scroll-dialog-title" style={{backgroundColor:"#64aadc"}}  >
            <b>{prop.name} recipe (ingredients and instructions) by - Javed </b></DialogTitle>
        
        <DialogContent dividers={true}>
        <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
              <h1 style={{paddingLeft:"10%", paddingRight:"0%",color:"#64aadc"}}>{prop.ingredients.length} Ingredients</h1> 
            
            {prop.ingredients
              .map(
               
                (ingredient, index) =>  (  <OneIngredient image={IngredientsList[ingredient.code].image} description={IngredientsList[ingredient.code].name + " : " +ingredient.quantity}/>   ),
              )
              }
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
              <h1 style={{paddingLeft:"10%", paddingRight:"0%", color:"#64aadc"}}>Instructions</h1>
            {instructionsList.map(
                (step) =>  (<OneInstruction image={"https://img.icons8.com/color/48/000000/cook-male--v1.png"} description={ step}/> ),
              )
              }
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{backgroundColor:"#64aadc", fontWeight: "bold"}} >
          <Button onClick={prop.handleClose} color="secondary" style={{fontWeight: "1000"}}>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
