import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Element } from 'react-scroll'
import { ClickActionEvent } from '../../util/analytics'

import quotesList from "./Data"

import OneQuote from './OneQuote'
 


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  cardHover: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "0 4px 8px 0 rgba(0,0,255,0.8), 0 6px 20px 0 rgba(0,0,255,0.8)",
    cursor: 'pointer',
    opacity:'0.7',
    transition: '0.2s linear'
    
  },
  cardMedia: {
    paddingTop: '22.25%',
    height: 70,
  },
  cardContent: {
    flexGrow: 1,
  },
}));


export default function FavQuotes() {
  
  const classes = useStyles();


  const [selectedItem, setSelectedItem] = React.useState(0)

  const PreviousQuote = (t)  =>{
    ClickActionEvent("FavQuotesBy "+quotesList[selectedItem].name, 'favourate quotes section', 'favourate previous quote clicked')
    if(selectedItem==0)
      setSelectedItem(quotesList.length-1)
    else
      setSelectedItem(selectedItem-1)
    
  }

  const NextQuote = (event)  =>{
    ClickActionEvent("FavQuotesBy "+quotesList[selectedItem].name, 'favourate quotes section', 'favourate next quote clicked')

    if(selectedItem==quotesList.length-1)
      setSelectedItem(0)
    else
      setSelectedItem(selectedItem+1)
  }

  return (
    <React.Fragment>
 
      <Element id='quotes' name='quotes' style={{backgroundColor:"#A4E5E0"}}>

        <div className={classes.heroContent} style={{backgroundColor:"#A4E5E0"}}>
          <Container maxWidth="lx">
            <Typography component="h1" variant="h3" align="center" style={{color:"#0c6170"}} gutterBottom>
              My Favourite Quotes 💡
            </Typography>
            <Typography variant="h6" align="center" style={{color:"#0c6170"}} paragraph>
              These are some Quotes which I find inspiring...
            </Typography>
          </Container>

        </div>
        <Container align="center" className={classes.cardGrid} maxWidth="lx">
              <OneQuote item={quotesList[selectedItem]}/>
              <Button style={{backgroundColor:"#0c6170", margin:"10px", color:"#dbf5f0"}} size="small" color="primary" onClick={()=>PreviousQuote()}>
                Previous
              </Button>
              <Button size="small" color="primary" style={{backgroundColor:"#0c6170", margin:"10px", color:"#dbf5f0"}} onClick={()=>NextQuote()}>
                Next
              </Button>
        </Container>
      </Element>
 
    </React.Fragment>
  );
}
